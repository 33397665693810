import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

import { toast } from 'react-toastify';
import { Scope } from '@rocketseat/unform';
import { RiMailSendLine } from 'react-icons/ri';
import { FaRegTrashAlt, FaTrashAlt } from 'react-icons/fa';
import { MdGroupAdd, MdPersonAdd } from 'react-icons/md';
import SweetAlert2 from 'react-sweetalert2';
import { isPast } from 'date-fns';

import {
  Button,
  ButtonsContainer,
  StatusContainer,
  HeadButtonsContainer,
  HeadContainer,
  LicenseContainer,
  Title,
  StatusLabel,
  Status,
  ListContainer,
} from './styles';
import { CreateLicenseForm } from '../CreateLicenseForm';
import { CreateLicenseBulkForm } from '../CreateLicenseBulkForm';

import ButtonComponent from '~/components/Button';
import { Input } from '~/components/Form';
import api from '~/services/api';
import { getLabel } from '~/utils';

export default function LicensesList({ contract }) {
  const [fields, setFields] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState(false);
  const [showCreateLicense, setShowCreateLicense] = useState(false);
  const [showCreateBulkLicense, setShowCreateBulkLicense] = useState(false);
  const [showDeleteMany, setShowDeleteMany] = useState(false);
  const activeLicenses = fields.filter((field) => field?.started_at).length;

  function handleCheck(id) {
    setFields((prev) =>
      prev.map((field) => ({
        ...field,
        checked: field.id === id ? !field.checked : field.checked,
      })),
    );
  }

  function handleCheckAll() {
    const fieldsChecked = fields?.map((field) => ({
      ...field,
      checked: !allChecked,
    }));
    setFields(fieldsChecked);

    setAllChecked(!allChecked);
  }

  async function inviteEmail(licenseId) {
    try {
      await api.post(`/b2b/licenses/${licenseId}/invite`);

      toast.success('E-mail enviado com sucesso!');
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  }

  const loadLicenses = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data: response } = await api.get(
        `/b2b/${contract?.id}/licenses`,
        {
          params: { per_page: contract?.licenses_total },
        },
      );

      setFields(
        response.data.map((field) => ({
          ...field,
          checked: false,
        })),
      );
    } catch (err) {
      toast.error('Erro ao buscar dados das licenças.');
    } finally {
      setIsLoading(false);
    }
  }, [contract?.id, contract?.licenses_total]);

  async function handleRemoveLicense(licenseId) {
    try {
      await api.delete(`/b2b/licenses/${licenseId}`);

      toast.success('Licença excluída com sucesso');
    } catch {
      toast.error('Não foi possível excluir a licença');
    }

    setSelectedLicense(null);
    loadLicenses();
  }

  async function handleRemoveManyLicense() {
    const fieldsToExclude = fields
      ?.filter((field) => field?.checked)
      .map((field) => field?.id);

    try {
      setIsDeleting(true);
      await api.post(`/b2b/licenses/delete-many`, {
        licenses: fieldsToExclude,
        contract_id: contract?.id,
      });

      toast.success('Licenças excluídas com sucesso');
    } catch {
      toast.error('Não foi possível excluir as licenças');
    }

    setIsDeleting(false);
    loadLicenses();
    setShowDeleteMany(false);
  }

  useEffect(() => {
    if (contract?.id) {
      loadLicenses();
    }
  }, [contract.id, loadLicenses]);

  if (isLoading) {
    return 'Carregando...';
  }

  return (
    <>
      <header style={{ margin: '3rem 0 1rem' }}>
        <HeadContainer>
          <Title>Lista de usuários</Title>
          <p>
            Licenças em uso:
            <strong>
              {' '}
              {activeLicenses}/{contract?.licenses_total}
            </strong>
          </p>
        </HeadContainer>

        <HeadButtonsContainer>
          <ButtonComponent
            icon={MdPersonAdd}
            color="success"
            onClick={() => setShowCreateLicense((state) => !state)}
          >
            NOVO USUÁRIO
          </ButtonComponent>

          <ButtonComponent
            icon={MdGroupAdd}
            color="default"
            onClick={() => setShowCreateBulkLicense((state) => !state)}
          >
            NOVO GRUPO
          </ButtonComponent>
          <ButtonComponent
            icon={FaTrashAlt}
            loading={isDeleting}
            disabled={!fields?.some((field) => field?.checked)}
            onClick={() => setShowDeleteMany(true)}
          />
        </HeadButtonsContainer>
      </header>

      <input
        type="checkbox"
        value={allChecked}
        checked={
          fields?.filter((field) => field?.checked)?.length === fields?.length
        }
        onChange={() => handleCheckAll()}
      />

      <ListContainer>
        {fields?.map((license, index) => {
          const isFirst = index === 0;

          return (
            <Scope path={`[${index}]`} key={`field-license-${license.id}`}>
              <LicenseContainer>
                <section>
                  <input
                    type="checkbox"
                    onChange={() => handleCheck(license?.id)}
                    value={license?.checked}
                    checked={license?.checked}
                    style={{
                      flex: '0 !important',
                      marginTop: isFirst && 45,
                    }}
                  />
                  <Input
                    label={
                      isFirst
                        ? getLabel(
                            'E-mail',
                            'E-mail para acesso à plataforma.',
                            true,
                          )
                        : null
                    }
                    value={license?.email}
                    readOnly
                  />

                  <StatusContainer>
                    {isFirst && (
                      <StatusLabel>
                        <h1>Status</h1>
                        <p>Status da conta</p>
                      </StatusLabel>
                    )}

                    <Status>
                      {license?.started_at ? 'Vigente' : 'Inativo'}
                    </Status>
                  </StatusContainer>

                  <ButtonsContainer
                    style={{ marginTop: isFirst && 42 }}
                    isFirst={isFirst}
                  >
                    <Button
                      type="button"
                      onClick={() => setSelectedLicense(license)}
                    >
                      <FaRegTrashAlt className="icon" />
                    </Button>

                    <Button
                      type="button"
                      onClick={() => {
                        inviteEmail(license?.id);
                      }}
                      disabled={
                        license?.last_invite_at
                          ? isPast(license?.last_invite_at)
                          : false
                      }
                    >
                      <RiMailSendLine className="icon" />
                    </Button>
                  </ButtonsContainer>
                </section>
              </LicenseContainer>
            </Scope>
          );
        })}
      </ListContainer>

      <SweetAlert2
        show={selectedLicense}
        title="Excluir licença?"
        text="Esta ação não poderá ser desfeita"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveLicense(selectedLicense?.id)}
        onResolve={() => setSelectedLicense(null)}
      />

      <SweetAlert2
        show={showDeleteMany}
        title="Excluir licenças?"
        text="Esta ação não poderá ser desfeita"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={handleRemoveManyLicense}
        onResolve={() => setShowDeleteMany(false)}
      />

      <CreateLicenseForm
        show={showCreateLicense}
        contractId={contract?.id}
        loadLicenses={loadLicenses}
        setShowCreateLicense={setShowCreateLicense}
      />

      <CreateLicenseBulkForm
        show={showCreateBulkLicense}
        contractId={contract?.id}
        loadLicenses={loadLicenses}
        setShowCreateBulkLicense={setShowCreateBulkLicense}
      />
    </>
  );
}

LicensesList.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.string,
    licenses_total: PropTypes.number,
  }).isRequired,
};
