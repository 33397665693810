import { transparentize } from 'polished';
import styled from 'styled-components';

export default styled.div`
  position: relative;
  text-align: center;
  margin: 5px 0 15px;
  color: ${transparentize(0.3, '#302B3E')};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #302b3e;
  }

  &::before,
  &::after {
    content: '';
    width: calc(50% - 60px);
    height: 1px;
    position: absolute;
    border-top: 1px dashed #000;
    border-color: ${transparentize(0.9, '#302B3E')};
  }

  &::before {
    left: 0;
    top: 10px;
  }

  &::after {
    right: 0;
    top: 10px;
  }
`;
