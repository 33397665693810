/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { MdClose } from 'react-icons/md';
import * as Yup from 'yup';

import Button from '~/components/Button';
import Page from '~/components/Page';
import api from '~/services/api';
import { Checkbox, FileInput, Form, Input } from '~/components/Form';
import { getLabel } from '~/utils';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é um campo obrigatório.'),
  description: Yup.string().required('Descrição é um campo obrigatório.'),
  identifier: Yup.string().required('Identificador é um campo obrigatório.'),
  template: Yup.string(),
  is_signable: Yup.boolean(),
});

function DocumentsForm({ match, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { id: documentId } = match.params;
  const isUpdatingPage = !!match.params.id;

  useEffect(() => {
    async function loadDocument(id) {
      try {
        setIsLoading(true);

        const { data } = await api.get(`/documents/${id}`);

        setForm(data);
      } catch (error) {
        toast.error('Não foi possível carregar o formulário.');
      } finally {
        setIsLoading(false);
      }
    }

    if (documentId) {
      loadDocument(documentId);
    }
  }, [documentId]);

  async function handleSubmit(formData) {
    try {
      setIsSubmitting(true);

      await api.postOrPut('/documents', documentId, formData);

      toast.success('Documento salvo com sucesso!');

      history.push('/documents/list');
    } catch (error) {
      toast.error('Algo deu errado.');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdatingPage ? 'Editar' : 'Novo'} documento`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/documents/list">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={form} onSubmit={handleSubmit}>
        <section>
          <Input label="Nome" name="name" placeholder="Nome do documento" />
          <Checkbox
            label={getLabel(
              'Assinável',
              'Informe se o documento necessita de assinatura do usuário',
              false,
            )}
            name="is_signable"
          />
        </section>

        <section>
          <Input
            label="Descrição"
            name="description"
            placeholder="Descrição"
            multiline
          />
        </section>

        <section>
          <FileInput
            label="Template"
            name="template"
            path="documents/templates"
            placeholder="Adicione o template do arquivo enviado para assinatura do documento (PDF)"
          />

          <Input label="Identificador" name="identifier" placeholder="Identificador do documento" />
        </section>

        <Button type="submit" size="block" loading={isSubmitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

DocumentsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

DocumentsForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default DocumentsForm;
