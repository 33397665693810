import { transformDateFromDatePicker } from '~/utils';

export const reminderFormMapper = {
  mapToHttp: (data) => {
    const payload = {
      // COMMON FIELDS FOR REMINDERS
      title: data.name,
      local: data.local,
      banner: data.banner,
      cta_url: data.cta_link,
      cta_label: data.cta_label,
      is_free: !data.is_free,
      should_display_hours:
        data.should_display_hours === null ? true : !!data.should_display_hours,
      type: data.category?.value,
      description: data.description,
      is_featured: !!data.is_featured,
      is_recommended: !!data.is_recommended,
      starts_at: transformDateFromDatePicker(data.start_date),
      ends_at: transformDateFromDatePicker(data.end_date),
      is_college: !!data.is_college,

      // COMMON BUT SPECIFIC FOR CALENDAR REMINDER RECURRENCY
      interval: data.interval,
      frequency: data.frequency?.value,
      should_repeat: !!data.should_repeat,
      exception_dates: data.exception_dates?.map(transformDateFromDatePicker),

      recurrency_end_date: transformDateFromDatePicker(
        data.recurrency_end_date,
      ),

      // EVENT RELATED FIELDS
      event_id: data.event?.value,
      subscribers_limit: data.subscribers_limit,
      restrict_subscribers: !!data.restrict_subscriptions,
      tag_skills: data.tag_skills?.map(({ value }) => value) || [],

      // EVENT AND CONTENT RELATED FIELDS
      tag_level_id: data.tag_level?.value,
      educators: data.educators?.map(({ value }) => value) || [],
      technologies: data?.technologies?.map(({ value }) => value) || [],

      // OFFER RELATED FIELDS
      pluto_offer_slug: data.offer?.value,
    };

    return payload;
  },
};
