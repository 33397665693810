import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

import produce from 'immer';
import { MdClose, MdDateRange } from 'react-icons/md';
import { toast } from 'react-toastify';

import JourneyContext, {
  move,
  selectCurrent,
  updateJourney,
  updateNode,
  addNode,
  toggleDeleteNode,
} from './context';
import Form from './JourneyForm';
import JourneyItem from './JourneyItem';
import NodeForm from './NodeForm';
import NodeItem from './NodeItem';
import Nodes from './Nodes';
import { Container } from './styles';
import { extractNodeData, mapDataWithTitle } from './utils';

import { showError } from '~/utils';
import api from '~/services/api';
import Page from '~/components/Page';
import Button from '~/components/Button';

function JourneyForm({ match, history }) {
  const { id: journeyId } = match.params;

  const [journey, setJourney] = useState({
    title: '',
    description: '',
    about: '',
    csat_score: null,
    csat_reviews: null,
    certificate_description: '',
    thumbnail: '',
    icon: '',
    forum_id: '',
    is_recording: false,
    has_cashback: false,
    is_searchable: false,
    is_filterable: false,
    is_old: false,
    is_enabled_on_catalog: false,
    is_certificable: false,
    is_free: false,
    is_offer: false,
    is_entry_course: false,
    related_specialization_id: null,
    content_type: 'COURSE',
    workload: null,
    released_at: null,
    nodes: [],
    current: 'current',
    tag_technologies: [],
    tag_level: null,
    tag_skills: [],
    tag_default: [],
    has_after_access: false,
    has_trial_as_bonus: false,
    testimonials: [],
    educators: [],
    intro_video_id: '',
    free_until: null,
    teams: [],
    should_redirect_to_internal_page: false,
    term_of_use_id: '',
    show_info: false,
    circle_workspace_id: null,
  });

  const [lessonGroups, setLessonGroups] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [categories, setCategories] = useState([]);
  const [forums, setForums] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFormData, setIsLoadingFormData] = useState(false);

  const isUpdating = !!journeyId;

  const loadJourney = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        const { data: response } = await api.get(`/journeys/${id}`);

        setJourney(
          produce(response, (draft) => {
            draft.current = 'journey';
            draft.currentIndex = -1;
            draft.currentIsNew = false;
            draft.nodes = draft.nodes.map((node, index) => ({
              ...node,
              originalIndex: index,
              deleted: false,
            }));
          }),
        );
      } catch (err) {
        showError(err);
        history.push('/journeys');
      } finally {
        setIsLoading(false);
      }
    },
    [history],
  );

  const handleSave = useCallback(
    (holdPage) => {
      return async () => {
        try {
          setIsLoading(true);

          const order = 0;

          const nodes = journey.nodes.map(extractNodeData(order));
          const {
            title,
            about,
            csat_score,
            csat_reviews,
            slug,
            description,
            content_type,
            type,
            certificate_description,
            is_recording,
            is_filterable,
            is_searchable,
            is_old,
            is_enabled_on_catalog,
            is_certificable,
            is_free,
            is_offer,
            is_entry_course,
            related_specialization_id,
            pluto_offer_slug,
            has_cashback,
            released_at,
            workload,
            thumbnail,
            icon,
            forum_id: forum,
            scope,
            tag_technologies,
            tag_level,
            tag_skills,
            tag_default,
            has_after_access,
            has_trial_as_bonus,
            offer_url,
            related_journey_id,
            is_visible_for_users_with_access,
            testimonials,
            educators,
            landing_page_url,
            intro_video_id,
            free_until,
            teams,
            should_redirect_to_internal_page,
            term_of_use_id,
            circle_workspace_id,
          } = journey;

          const data = {
            title,
            about,
            csat_score,
            csat_reviews,
            slug,
            type,
            description,
            certificate_description,
            is_recording,
            thumbnail,
            icon,
            nodes,
            forum_id: forum,
            scope,
            is_filterable,
            is_searchable,
            is_old,
            is_enabled_on_catalog,
            is_certificable,
            is_free,
            is_offer,
            is_entry_course,
            related_specialization_id,
            content_type,
            released_at,
            workload,
            has_cashback,
            pluto_offer_slug,
            tag_technologies: tag_technologies.map((tag) =>
              tag?.id ? tag?.id : tag,
            ),
            tag_level,
            tag_skills: tag_skills.map((tag) => (tag?.id ? tag?.id : tag)),
            tag_default: tag_default.map((tag) => (tag?.id ? tag?.id : tag)),
            has_after_access,
            has_trial_as_bonus,
            offer_url,
            related_journey_id,
            is_visible_for_users_with_access,
            testimonials,
            educators,
            landing_page_url,
            intro_video_id,
            free_until,
            circle_workspace_id,
            teams,
            should_redirect_to_internal_page,
            term_of_use_id,
          };

          await api.postOrPut('/journeys', journeyId, data);

          toast.success('Journey salvo com sucesso!');

          holdPage && (await loadJourney(journeyId));

          !holdPage && history.push('/journeys');
        } catch (err) {
          showError(err);
        } finally {
          setIsLoading(false);
        }
      };
    },
    [history, journey, journeyId, loadJourney],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setIsLoadingFormData(true);

        const [
          lessonGroupsRes,
          clustersRes,
          challengesRes,
          categoriesRes,
          forumsRes,
        ] = await Promise.all([
          api.get('/lesson-groups?showAll=true'),
          api.get('/clusters?showAll=true'),
          api.get('/challenges?showAll=true'),
          api.get('/journey-node-categories'),
          api.get('/forums', { query: { all: true } }),
        ]);

        const lessonGroupsData = mapDataWithTitle(lessonGroupsRes.data.data);
        const clustersData = mapDataWithTitle(clustersRes.data.data);
        const challengesData = challengesRes.data.data;
        const categoriesData = categoriesRes.data;
        const forumData = forumsRes.data.data;

        setLessonGroups(lessonGroupsData);
        setClusters(clustersData);
        setChallenges(challengesData);
        setCategories(categoriesData);
        setForums(forumData);
      } catch (err) {
        showError(err);
      } finally {
        setIsLoadingFormData(false);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    if (journeyId) {
      loadJourney(journeyId);
    } else {
      setJourney((prevState) =>
        produce(prevState, (draft) => {
          draft.current = 'journey';
        }),
      );
    }
  }, [journeyId, loadJourney]);

  function renderNodeItem(node, index) {
    return (
      <NodeItem
        key={index}
        index={index}
        node={node}
        selected={index === journey.currentIndex}
      />
    );
  }

  function renderSaveButton(holdPage) {
    return (
      <Button
        type="button"
        onClick={handleSave(holdPage)}
        disabled={journey.nodes.length === 0}
        loading={isLoading || isLoadingFormData}
      >
        {holdPage ? 'Salvar e continuar editando' : 'Salvar jornada'}
      </Button>
    );
  }

  function renderForm() {
    if (journey.current === 'journey') {
      return <Form />;
    }

    if (journey.currentIndex === -1) {
      return <p>Selecione um node para poder editar</p>;
    }

    return journey.nodes.map(
      (node, index) =>
        index === journey.currentIndex && (
          <NodeForm node={node} key={node.id || index} journey={journey} />
        ),
    );
  }

  return (
    <JourneyContext.Provider
      value={{
        journey,
        updateJourney: updateJourney(setJourney, journey),
        selectCurrent: selectCurrent(setJourney, journey),
        move: move(setJourney, journey),
        addNode: addNode(setJourney, journey),
        updateNode: updateNode(setJourney, journey),
        toggleDeleteNode: toggleDeleteNode(setJourney, journey),
        lessonGroups,
        clusters,
        challenges,
        categories,
        forums,
        isLoadingFormData,
      }}
    >
      <Page loading={isLoading}>
        <header>
          <h1>{`${isUpdating ? 'Editar' : 'Nova'} jornada`}</h1>

          <div>
            <Button
              icon={MdDateRange}
              color="success"
              to={`/journeys/${match.params.id}/teams-exhibition`}
              disabled={!isUpdating}
            >
              Exibição por times
            </Button>
            {renderSaveButton()}
            {renderSaveButton(true)}
            <Button icon={MdClose} color="cancel" to="/journeys">
              Cancelar
            </Button>
          </div>
        </header>

        <Container>
          <main>
            {isLoading ? <p>Carregando informações</p> : renderForm()}
          </main>

          <aside>
            <Nodes>
              <JourneyItem selected={journey.current === 'journey'} />
              {journey.nodes.map(renderNodeItem)}
            </Nodes>
          </aside>
        </Container>
      </Page>
    </JourneyContext.Provider>
  );
}

JourneyForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default JourneyForm;
