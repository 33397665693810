/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';

import { MdAdd, MdSearch } from 'react-icons/md';
import { escape } from 'querystring-browser';
import { useHistory } from 'react-router-dom';

import { FormContainer, HeaderContainer } from './styles';

import Button from '~/components/Button';
import { Input } from '~/components/Form';
import { useCan } from '~/utils';

export function DocumentsListHeader() {
  const [search, setSearch] = useState('');
  const history = useHistory();
  const can = useCan();

  function handleSubmitSearch(e) {
    e.preventDefault();
    history.push(`/documents/1/?search=${escape(search)}`);
  }

  return (
    <HeaderContainer>
      <h1>Documentos</h1>

      <div>
        <FormContainer>
          <form onSubmit={handleSubmitSearch}>
            <Input
              type="text"
              placeholder="Digite sua busca..."
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              unform={false}
            />

            <Button type="submit" icon={MdSearch} color="success" />
          </form>

          {can('edit_documents') && (
            <Button icon={MdAdd} color="success" to="/documents/new">
              Novo
            </Button>
          )}
        </FormContainer>
      </div>
    </HeaderContainer>
  );
}
