/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useOffers } from '@shared/skylab/graphql/pluto';

import plutoApi from '~/services/pluto.api';
import { showError } from '~/utils';

export function useOfferForm({ history, purchase }) {
  const { loading: loadingOffers, data: offersData } = useOffers();
  const [showOfferChangeModal, setShowOfferChangeModal] = useState(false);

  const offerChangeForm = useForm({});

  async function handleOfferChangeRequest(purchase) {
    const newOffer = offerChangeForm.getValues('new_offer');

    const payload = {
      purchaseId: purchase.transaction,
      newOfferId: newOffer.id,
    };

    try {
      await plutoApi.post('offers/change', payload);

      toast.success(
        `Oferta trocada com sucesso!
            A página será atualizada automaticamente em alguns instantes!`,
        {
          autoClose: 3000,
          onClose: () => {
            history.push(`?highlight=product`);
            window.location.reload();
          },
        },
      );
    } catch (err) {
      showError(err);
    }
  }

  const offers =
    offersData?.offers
      ?.filter((offer) => offer.slug !== purchase.product)
      .map((offer) => ({
        ...offer,
        value: offer.id,
        label: `${offer.title} (${offer.slug})`,
      })) || [];

  return {
    offers,
    loadingOffers,
    offerChangeForm,
    showOfferChangeModal,
    setShowOfferChangeModal,
    handleOfferChangeRequest,
  };
}
