import React from 'react';

import {
  FaCertificate,
  FaMedal,
  FaStar,
  FaTag,
  FaWpforms,
  FaAddressCard,
  FaGift,
  FaGifts,
  FaEnvelopeOpenText,
} from 'react-icons/fa';
import {
  MdArchive,
  MdAssignment,
  MdBook,
  MdBuild,
  MdBusiness,
  MdCollectionsBookmark,
  MdDashboard,
  MdFeedback,
  MdFlag,
  MdForum,
  MdGavel,
  MdGroup,
  MdLibraryBooks,
  MdPlace,
  MdShoppingCart,
  MdSupervisorAccount,
  MdGroupWork,
} from 'react-icons/md';
import { GiDrippingTube } from 'react-icons/gi';
import { PiStudentFill } from 'react-icons/pi';
import {
  RiArticleFill,
  RiBarChartFill,
  RiCoupon2Fill,
  RiCalendar2Fill,
} from 'react-icons/ri';

export const navLinks = [
  {
    name: 'Geral',
    links: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: <MdDashboard size={24} />,
        permissions: [],
      },
      {
        name: 'Usuários',
        url: '/users',
        icon: <MdGroup size={24} />,
        permissions: ['view_users'],
      },
      {
        name: 'Educadores',
        url: '/educators',
        icon: <MdGroup size={24} />,
        permissions: ['view_users'],
      },
      {
        name: 'Times',
        url: '/teams',
        icon: <MdFlag size={24} />,
        permissions: ['view_teams'],
      },
      {
        name: 'Alteração de dados',
        url: '/sensitive-information',
        icon: <MdFeedback size={24} />,
        permissions: ['view_sensitive-information'],
      },
      {
        name: 'Fóruns',
        url: '/forums',
        icon: <MdForum size={24} />,
        permissions: ['view_forums'],
      },
      {
        name: 'Insígnias',
        url: '/badges',
        icon: <FaMedal size={24} />,
        permissions: ['view_badges'],
      },
      {
        name: 'Documentos',
        url: '/documents/list',
        icon: <FaEnvelopeOpenText size={24} />,
        permissions: ['view_documents'],
      },
    ],
  },
  {
    name: 'Conteúdo',
    links: [
      {
        name: 'Jornadas',
        url: '/journeys',
        icon: <MdAssignment size={24} />,
        permissions: ['edit_journeys'],
      },
      {
        name: 'Clusters',
        url: '/clusters',
        icon: <MdLibraryBooks size={24} />,
        permissions: ['edit_clusters'],
      },
      {
        name: 'Grupo de aulas',
        url: '/lesson-groups',
        icon: <MdCollectionsBookmark size={24} />,
        permissions: ['edit_lesson-groups'],
      },
      {
        name: 'Aulas',
        url: '/lessons',
        icon: <MdBook size={24} />,
        permissions: ['edit_lessons'],
      },
      {
        name: 'Tags',
        url: '/content-tags',
        icon: <FaTag size={20} />,
        permissions: ['edit_lessons'],
      },
      {
        name: 'Desafios',
        url: '/challenges',
        icon: <MdGavel size={24} />,
        permissions: ['view_challenges'],
      },
      {
        name: 'Formulários',
        url: '/forms',
        icon: <FaWpforms size={24} />,
        permissions: ['view_forms'],
      },
      {
        name: 'Micro Certificados',
        url: '/micro-certificates',
        icon: <FaCertificate size={24} />,
        permissions: ['view_forms'],
      },
      {
        name: 'Banners da Home',
        url: '/banners',
        icon: <FaAddressCard size={24} />,
        permissions: ['login_admin'],
      },
      {
        name: 'Experimentos (A/B)',
        url: '/experiments',
        icon: <GiDrippingTube size={24} />,
        permissions: ['login_admin'],
      },
    ],
  },
  {
    name: 'Guia de estudos',
    links: [
      {
        name: 'Tarefas',
        url: '/guide/tasks',
        icon: <MdAssignment size={24} />,
        permissions: ['view_goals'],
      },
      {
        name: 'Fases',
        url: '/guide/levels',
        icon: <RiBarChartFill size={24} />,
        permissions: ['view_goals'],
      },
      {
        name: 'Objetivos',
        url: '/guide/goals',
        icon: <FaStar size={24} />,
        permissions: ['view_goals'],
      },
      {
        name: 'Auditorias',
        url: '/guide/users/audits',
        icon: <MdArchive size={24} />,
        permissions: ['view_goals'],
      },
    ],
  },
  {
    name: 'Navigation',
    links: [
      {
        name: 'Autoavaliações',
        url: '/navigations',
        icon: <MdPlace size={24} />,
        permissions: ['view_navigations'],
      },
    ],
  },
  {
    name: 'Compras e Trials',
    links: [
      {
        name: 'Produtos',
        url: '/products',
        icon: <MdAssignment size={24} />,
        permissions: ['view_products'],
      },
      {
        name: 'Bônus',
        url: '/bonuses',
        icon: <FaGifts size={24} />,
        permissions: ['login_admin'],
      },
      {
        name: 'Ofertas',
        url: '/offers',
        icon: <MdShoppingCart size={24} />,
        permissions: ['view_offers'],
      },
      {
        name: 'Cupons',
        url: '/coupons',
        icon: <RiCoupon2Fill size={24} />,
        permissions: ['view_coupons'],
      },
      {
        name: 'Trials',
        url: '/trials',
        icon: <FaMedal size={24} />,
        permissions: ['view_trials'],
      },
    ],
  },
  {
    name: 'Serviço de certificados',
    links: [
      {
        name: 'Cursos',
        url: '/certificate-service/courses',
        icon: <MdAssignment size={24} />,
        permissions: ['view_certificates'],
      },
      {
        name: 'Educators',
        url: '/certificate-service/educators',
        icon: <MdGroup size={24} />,
        permissions: ['view_certificates'],
      },
      {
        name: 'Certificados',
        url: '/certificate-service/certificates',
        icon: <FaCertificate size={24} />,
        permissions: ['view_certificates'],
      },
    ],
  },
  {
    name: 'Calendário',
    links: [
      {
        name: 'Lembretes',
        url: '/reminders',
        icon: <RiCalendar2Fill size={24} />,
        permissions: ['login_admin'],
      },
    ],
  },
  {
    name: 'Eventos',
    links: [
      {
        name: 'Eventos',
        url: '/events',
        icon: <MdAssignment size={24} />,
        permissions: ['edit_lessons'],
      },
      {
        name: 'Trilhas',
        url: '/trails',
        icon: <MdBook size={24} />,
        permissions: ['edit_lessons'],
      },
    ],
  },
  {
    name: 'Faculdade',
    links: [
      {
        name: 'Matrículas',
        url: '/documents/users',
        icon: <PiStudentFill size={24} />,
        permissions: ['approve_user_documents'],
      },
    ],
  },
  {
    name: 'Para Empresas',
    links: [
      {
        name: 'Empresas',
        url: '/b2b/companies',
        icon: <MdBusiness size={24} />,
        permissions: ['btb:contracts_view'],
      },
      {
        name: 'Contratos',
        url: '/b2b/contracts',
        icon: <RiArticleFill size={24} />,
        permissions: ['btb:contracts_view'],
      },
    ],
  },
  {
    name: 'Termos de uso',
    links: [
      {
        name: 'Termos de uso',
        url: '/terms',
        icon: <MdAssignment size={24} />,
        permissions: ['view_terms'],
      },
    ],
  },
  {
    name: 'Notificações',
    links: [
      {
        name: 'Contextos',
        url: '/notifications/categories/groups',
        icon: <MdGroupWork size={24} />,
        permissions: ['view_category_groups'],
      },
      {
        name: 'Categorias',
        url: '/notifications/categories',
        icon: <MdAssignment size={24} />,
        permissions: ['view_categories'],
        isActive: (_, { pathname }) =>
          pathname.startsWith('/notifications/categories') &&
          !pathname.startsWith('/notifications/categories/groups'),
      },
      {
        name: 'Notificações',
        url: '/notifications',
        icon: <MdAssignment size={24} />,
        permissions: ['view_notifications'],
        isActive: (_, { pathname }) =>
          pathname.startsWith('/notifications') &&
          !pathname.startsWith('/notifications/categories'),
      },
    ],
  },
  {
    name: 'Member Get Member',
    links: [
      {
        name: 'Prêmios',
        url: '/rewards',
        icon: <FaGift size={24} />,
        permissions: ['view_rewards'],
      },
      {
        name: 'Resgates',
        url: '/redeems',
        icon: <FaStar size={24} />,
        permissions: ['view_redeems'],
      },
    ],
  },
  {
    name: 'Circle',
    links: [
      {
        name: 'Workspaces',
        url: '/circle/workspaces',
        icon: <MdSupervisorAccount size={24} />,
        permissions: ['view_roles'],
      },
      {
        name: 'Usuários Integrados',
        url: '/circle/integrations',
        icon: <MdBuild size={24} />,
        permissions: [],
      },
    ],
  },
  {
    name: 'Administração',
    links: [
      {
        name: 'Grupo de permissões',
        url: '/roles',
        icon: <MdSupervisorAccount size={24} />,
        permissions: ['view_roles'],
      },
      {
        name: 'Configurações do sistemas',
        url: '/system',
        icon: <MdBuild size={24} />,
        permissions: [],
      },
    ],
  },
];
