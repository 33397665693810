import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import { CgSpinner } from 'react-icons/cg';

import { Container, Loading } from './styles';

function Button({
  icon: Icon,
  iconSize,
  iconColor,
  children,
  loading,
  disabled,
  to,
  type,
  ...props
}) {
  const tag = to ? Link : 'button';
  const ButtonTag = Container.withComponent(tag);

  return (
    <ButtonTag
      type={type}
      disabled={loading || disabled}
      {...(to ? { to } : null)}
      {...props}
    >
      {Icon && <Icon color={iconColor} size={iconSize} />}
      {children ? <div className="children">{children}</div> : null}

      {loading && (
        <Loading>
          <CgSpinner />
        </Loading>
      )}
      {/* )} */}
    </ButtonTag>
  );
}

Button.propTypes = {
  children: PropTypes.string,
  size: PropTypes.oneOf(['default', 'extra-small', 'small', 'big', 'block']),
  color: PropTypes.oneOf(['default', 'cancel', 'danger', 'warning', 'success']),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  to: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
};

Button.defaultProps = {
  children: '',
  size: 'default',
  color: 'default',
  icon: null,
  iconColor: '#fff',
  iconSize: 20,
  to: null,
  type: 'button',
  loading: false,
  disabled: false,
  outline: false,
};

export default Button;
