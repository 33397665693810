import axios from 'axios';

const plutoApi = axios.create({
  baseURL: process.env.REACT_APP_PLUTO_URL.replace('/graphql', ''),
  headers: {
    AUTH_ACCESS_TOKEN: process.env.REACT_APP_PLUTO_AUTH_ACCESS_TOKEN,
  },
});

plutoApi.interceptors.request.use((config) => {
  const token = localStorage.getItem('@Skylab:token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default plutoApi;
