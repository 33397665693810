import PropTypes from 'prop-types';
import React from 'react';

import { addHours, differenceInDays, isAfter } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Container } from './styles';

import ClipboardButton from '~/components/ClipboardButton';
import { Datepicker, Input } from '~/components/HookForm';
import { Table } from '~/components/Table';
import Button from '~/components/Button';
import { showError } from '~/utils';
import api from '~/services/api';

export const TeamsUsersForm = ({ purchaseId, userId, teams, viewOnly }) => {
  const form = useForm({
    values: {
      teams: teams?.map((team) => ({
        id: team.id,
        user_id: team.user_id,
        start_date: team.start_date && new Date(team.start_date),
        end_date: team.end_date && new Date(team.end_date),
        renewable_at: team.renewable_at && new Date(team.renewable_at),
      })),
    },
  });

  async function handleSubmit(data) {
    try {
      const updatedData = {
        ...data,
        start_date: addHours(data.start_date, 3),
        end_date: addHours(data.end_date, 3),
      };

      await api.put(`purchases/teams/${purchaseId}`, updatedData);

      toast.success(
        `Acessos da compra atualizado
          A página será atualizada automaticamente em alguns instantes!`,
        {
          autoClose: 3000,
          onClose: () => {
            window.location.reload();
          },
        },
      );
    } catch (err) {
      showError(err);
    }
  }

  return (
    <Container>
      <h1>Acessos</h1>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Table>
            <thead>
              <tr>
                <th>Time</th>
                <th>Usuário</th>
                <th>Início do acesso</th>
                <th>Fim do Acesso</th>
                <th>Tempo de acesso</th>
                <th>Tempo</th>
              </tr>
            </thead>

            <tbody>
              {teams?.map((team, index) => (
                <tr
                  key={team.id}
                  style={
                    userId !== team.user_id ? { background: '#f7c0bb' } : {}
                  }
                >
                  <td>
                    <section>
                      <Input
                        type="hidden"
                        name={`teams[${index}].id`}
                        value={team.id}
                      />

                      <strong>{team.team.title}</strong>
                      <span>{team.team.identifier}</span>
                    </section>
                  </td>

                  <td>
                    <Input
                      type="text"
                      name={`teams[${index}].user_id`}
                      label="ID do Usuário"
                      defaultValue={team.user_id}
                    />
                  </td>

                  <td>
                    <Datepicker
                      label="Data início"
                      name={`teams[${index}].start_date`}
                      readOnly={viewOnly}
                    />
                  </td>

                  <td>
                    <Datepicker
                      label="Data fim"
                      name={`teams[${index}].end_date`}
                      readOnly={viewOnly}
                    />
                  </td>

                  <td>
                    <Datepicker
                      label="Renova em"
                      name={`teams[${index}].renewable_at`}
                      readOnly={viewOnly}
                    />
                  </td>

                  <td>
                    <section
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      {`${differenceInDays(
                        new Date(team.end_date),
                        new Date(team.start_date),
                      )} dias de acesso`}

                      <small>
                        {isAfter(team.start_date, new Date())
                          ? `Começa em ${differenceInDays(
                              new Date(team.start_date),
                              new Date(),
                            )} dias`
                          : `Restam ${differenceInDays(
                              new Date(team.end_date),
                              new Date(),
                            )} dias`}
                      </small>
                    </section>
                  </td>

                  <td />

                  <td>
                    <ClipboardButton title="Copiar ID" value={team.id} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {!viewOnly && (
            <Button
              type="submit"
              size="block"
              loading={form.formState.isSubmitting}
            >
              Salvar Acessos
            </Button>
          )}
        </form>
      </FormProvider>
    </Container>
  );
};

TeamsUsersForm.propTypes = {
  purchaseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      user_id: PropTypes.string.isRequired,
      team_id: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      purchase_id: PropTypes.string.isRequired,
      start_date: PropTypes.string.isRequired,
      end_date: PropTypes.string.isRequired,
      team: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        hotmart_id: PropTypes.string,
        starts_at: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        auto_enrollment: PropTypes.bool.isRequired,
        discord_roles: PropTypes.string,
        tag_id: PropTypes.string,
        chat_id: PropTypes.string,
        identifier: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        off_price: PropTypes.number,
        checkout: PropTypes.bool.isRequired,
        identifier_prefix: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  viewOnly: PropTypes.bool.isRequired,
};
