import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { toast } from 'react-toastify';

import JourneyContext from '../context';
import schema from './schema';
import TestimonialForm from '../TestimonialForm';
import { useLazyOffers } from '@shared/skylab/graphql/pluto';

import Button from '~/components/Button';
import {
  Form,
  Input,
  FileInput,
  Dropdown,
  Check,
  Datepicker,
} from '~/components/Form';
import { TagForms } from '~/components/TagForms';
import api from '~/services/api';
import { Badge } from '~/pages/content/coupons/Form/styles';

export const contentTypes = [
  { id: 'SPECIALIZATION', title: 'Formação' },
  { id: 'COURSE', title: 'Curso' },
  { id: 'EVENT', title: 'Evento' },
  { id: 'EXTRA', title: 'Conteúdo Complementar' },
  { id: 'COLLECTION', title: 'Coleção' },
];

export default function JourneyForm() {
  const { journey, updateJourney, forums, isLoadingFormData } =
    useContext(JourneyContext);

  const [journeysList, setJourneysList] = useState([]);
  const [journeysListLoading, setJourneysListLoading] = useState(true);

  const [contentType, setContentType] = useState(journey.content_type);
  const [isOld, setIsOld] = useState(journey.is_old);
  const [isFree, setIsFree] = useState(journey.is_free);
  const [isEnabledOnCatalog, setIsEnabledOnCatalog] = useState(
    journey.is_enabled_on_catalog,
  );

  const [isEntryCourse, setIsEntryCourse] = useState(
    Boolean(journey?.is_entry_course),
  );

  const [fetchOffers] = useLazyOffers();
  const [testimonials, setTestimonials] = useState(journey.testimonials || []);
  const [educators, setEducators] = useState([]);
  const [isLoadingEducators, setIsLoadingEducators] = useState(false);
  const [isCleaningJourneyCache, setIsCleaningJourneyCache] = useState(false);
  const [isSyncingLessons, setIsSyncingLessons] = useState(false);
  const [isReindexingJourneyContent, setIsReindexingJourneyContent] =
    useState(false);
  const [isCleaningJourneyProgressCache, setIsCleaningJourneyProgressCache] =
    useState(false);

  const [termsOfUseList, setTermsOfUseList] = useState([]);
  const [termsOfUseLoading, setTermsOfUseLoading] = useState(true);

  useEffect(() => {
    async function loadEducators() {
      try {
        setIsLoadingEducators(true);
        const response = await api.get('/educators', {
          params: {
            per_page: 999,
          },
        });

        setEducators(response.data.data);
      } catch (err) {
        toast.error('Erro ao carregar os educadores');
      } finally {
        setIsLoadingEducators(false);
      }
    }

    loadEducators();
  }, []);

  const loadLessons = useCallback(
    async (search) => {
      try {
        const response = await api.get('/lessons', {
          params: {
            search: search || journey.introVideo?.last?.tagged_title,
          },
        });

        return response.data.data;
      } catch (err) {
        toast.error('Erro ao carregar as aulas');
      }
    },
    [journey],
  );

  async function loadIntroVideo(lessonId) {
    if (!lessonId) return;

    try {
      const response = await api.get(`/lessons/${lessonId}`);

      return response.data;
    } catch (err) {
      toast.error('Erro ao carregar o vídeo de introdução');
    }
  }

  const loadJourneysList = useCallback(async () => {
    setJourneysListLoading(true);

    try {
      const { data } = await api.get('/journeys-list', {
        params: {
          isOld: !isOld,
          journeyId: journey.id,
        },
      });

      setJourneysList(data);
    } finally {
      setJourneysListLoading(false);
    }
  }, [isOld, journey.id]);

  const loadTermsOfUseList = useCallback(async () => {
    setTermsOfUseLoading(true);

    try {
      const { data } = await api.get('/terms', {
        params: {
          per_page: 999,
          distinct: true,
        },
      });

      setTermsOfUseList(data.data);
    } finally {
      setTermsOfUseLoading(false);
    }
  }, []);

  const loadOffers = useCallback(async () => {
    const response = await fetchOffers();

    return (
      response?.data?.offers
        ?.filter((offer) => !!offer.canUseCoupon)
        .map((offer) => ({
          ...offer,
          id: offer.slug,
        })) || []
    );
  }, [fetchOffers]);

  useEffect(() => {
    loadJourneysList();
    loadTermsOfUseList();
  }, [loadJourneysList, loadTermsOfUseList]);

  const handleAddTestimonial = useCallback(
    (testimonial) => {
      const newTestimonials = [...journey.testimonials, testimonial];

      updateJourney({
        ...journey,
        testimonials: newTestimonials,
      });

      setTestimonials(newTestimonials);
    },
    [journey, updateJourney],
  );

  async function loadTeams() {
    const response = await api.get('/teams', { params: { all: true } });

    return response.data;
  }

  async function loadWorkspaces() {
    const { data: response } = await api.get('/circle/workspaces');

    return (
      response?.data?.map((workspace) => ({
        id: workspace.id,
        title: workspace.name,
      })) || []
    );
  }

  async function loadJourneys() {
    const { data } = await api.get('/journeys', {
      params: {
        per_page: 999,
        content_type: 'SPECIALIZATION',
        fields: ['id', 'title'],
      },
    });

    const journeys = data?.data || [];

    return journeys;
  }

  const clearJourneyProgressCache = useCallback(async () => {
    try {
      setIsCleaningJourneyProgressCache(true);
      await api.delete(`/clear-cache/journey/${journey.id}/progress`);

      toast.success('Limpado o cache de progresso da jornada.');
    } catch {
      toast.error('Não foi possível limpar o cache do progress');
    } finally {
      setIsCleaningJourneyProgressCache(false);
    }
  }, [journey.id]);

  const clearJourneyCache = useCallback(async () => {
    try {
      setIsCleaningJourneyCache(true);
      await api.delete(`/clear-cache/journey/${journey.id}`);

      toast.success('Limpado o cache da jornada.');
    } catch {
      toast.error('Não foi possível limpar o cache da jornada');
    } finally {
      setIsCleaningJourneyCache(false);
    }
  }, [journey.id]);

  const reindexContent = useCallback(async () => {
    try {
      setIsReindexingJourneyContent(true);
      await api.put(`/reindex/journey/${journey.id}`);

      toast.success('Reindexado os conteudos da jornada.');
    } catch {
      toast.error('Não foi possível reindexar os conteúdos da jornada');
    } finally {
      setIsReindexingJourneyContent(false);
    }
  }, [journey.id]);

  const syncLessons = useCallback(async () => {
    try {
      setIsSyncingLessons(true);
      await api.post(`/journeys/sync-lessons`, { journeyId: journey.id });

      toast.success('Sincronizando aulas da jornada.');
    } catch {
      toast.error('Não foi possível sincronizar as aulas da jornada');
    } finally {
      setIsSyncingLessons(false);
    }
  }, [journey.id]);

  const render = useMemo(() => {
    return (
      <Form schema={schema} initialData={journey} onSubmit={updateJourney}>
        {journey && (
          <fieldset>
            <legend>Cache</legend>

            <div className="grid grid-cols-2 gap-2">
              <Button
                onClick={clearJourneyProgressCache}
                loading={isCleaningJourneyProgressCache}
              >
                Limpar cache do progresso
              </Button>

              <Button
                onClick={clearJourneyCache}
                loading={isCleaningJourneyCache}
              >
                Limpar cache da jornada
              </Button>

              <Button
                onClick={reindexContent}
                loading={isReindexingJourneyContent}
              >
                Reindexar conteúdos
              </Button>

              <Button onClick={syncLessons} loading={isSyncingLessons}>
                Sincronizar aulas
              </Button>
            </div>
          </fieldset>
        )}

        <section>
          <FileInput
            label="Thumbnail"
            description={`
              Utilizado nas listagens das jornadas e também na página da jornada.

              <em>⚠️ P.S: Esse campo será descontinuado após a entrada do catálogo.</em>
            `}
            name="thumbnail"
            placeholder="Thumbnail da Jornada"
            path="platform"
          />

          <FileInput
            label="Icone"
            description={`
              Este é o Ícone principal da jornada, será utilizado em todo lugar que for necessário listar.

              <b>Como:</b>
              <ul>
                <li>Catálogo</li>
                <li>Página da Jornada</li>
                <li>Fórums</li>
                <li>Continue assistindo</li>
                <li>Recomendações</li>
              </ul>
            `}
            name="icon"
            placeholder="Icone da Jornada"
            path="platform"
          />
        </section>

        <section>
          <Dropdown
            name="content_type"
            label="Tipo de conteúdo"
            options={contentTypes}
            placeholder="Selecione o tipo de conteúdo desta jornada"
            onChange={(selected) => setContentType(selected.id)}
            description={`
              Esse campo define a forma com que essa jornada será apresentada para o usuário no catálogo, mas também o peso dela em relação ao conteúdo.

              <ul>
                <li><b>Formação:</b> São nossas trilhas principais, conteúdos mais completos do início ao fim, como ReactJS, NodeJS, React Native, Java, etc.</li>
                <li><b>Cursos:</b> São cursos menores, como os projetos do ignite e cursos bônus. Ex.: Acessibilidade, Apps com Electron.</li>
                <li><b>Eventos:</b> São os eventos da Rocketseat. Ex.: NLW, Nitro, DoWhile.</li>
                <li><b>Conteúdo complementar:</b> São conteúdos soltos que apareceram no catálogo. Ex.: Conteúdos do plus e masterclasses.</li>
              </ul>
            `}
          />

          {['COURSE'].includes(contentType) && (
            <Check
              label="É um curso de entrada?"
              name="is_entry_course"
              onClick={({ value }) => {
                setIsEntryCourse(Boolean(value));
              }}
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
            />
          )}

          <Input
            label="Tipo"
            name="type"
            description={`
              Esse campo ele atualmente não é mais necessário, ele define o tipo de jornada, era utilizado antes do catálogo para definir se o conteúdo pertencia ao ignite ou a outro produto.

              <b>Alguns tipos existentes</b>
              <ul>
                <li>ignite</li>
                <li>explorer</li>
                <li>plus</li>
                <li>masterclass</li>
                <li>bonus</li>
                <li>ignite-bonus</li>
                <li>ignite-projects</li>
                <li>ignite-higher</li>
              </ul>
            `}
          />
        </section>

        {['COURSE'].includes(contentType) && isEntryCourse && (
          <section>
            <Dropdown
              name="related_specialization_id"
              label="Especialização relacionada"
              isClearable
              load={loadJourneys}
              placeholder="Selecione a especialização relacionada a esse curso"
            />
          </section>
        )}

        <section>
          <Input label="Título" name="title" />

          <Input
            label="Slug"
            name="slug"
            description="⚠️ Tenha certeza do que você está fazendo."
          />
        </section>

        <section>
          <Input
            multiline
            label="Descrição"
            name="description"
            placeholder="Descreve essa jornada"
          />
        </section>

        <section>
          <Input
            multiline
            label="Sobre"
            name="about"
            placeholder="Descreva essa jornada em detalhes"
          />
        </section>

        <section>
          <Dropdown
            label="Educadores"
            name="educators"
            options={educators}
            disabled={isLoadingEducators}
            placeholder={
              isLoadingEducators
                ? 'Carregando educadores'
                : 'Selecione os educadores dessa jornada'
            }
            isClearable
            getOptionLabel={(option) => option.user.name}
            getOptionValue={(option) => option.id}
            multiple
          />
        </section>

        {['SPECIALIZATION', 'COURSE'].includes(contentType) && (
          <section>
            <Dropdown
              name="forum_id"
              label="Fórum"
              options={forums}
              disabled={isLoadingFormData}
              isClearable
              placeholder="Selecione o fórum dessa jornada, caso exista."
              description="Apenas disponível para Formações e Cursos"
            />
          </section>
        )}

        <section>
          <Dropdown
            name="intro_video_id"
            async
            isClearable
            defaultOptions
            loadOptions={loadLessons}
            loadDefaultValue={loadIntroVideo}
            defaultValue={journey.intro_video_id}
            loadingMessage={() => 'Carregando aulas...'}
            label="Vídeo de Introdução"
            placeholder="Id da aula correspondente ao vídeo de introdução."
            description="Vídeo a ser exibido na página da jornada e no preview do catálogo"
          />

          <Dropdown
            name="term_of_use_id"
            label="Termos de uso da jornada"
            options={termsOfUseList}
            disabled={termsOfUseLoading}
            placeholder="Selecione o termo de uso dessa jornada, caso exista."
            isClearable
          />
        </section>

        <section>
          <Datepicker
            label="Data de liberação"
            name="released_at"
            showTimeSelect
            description={`
              Esse campo é responsável por 2 coisas:

              <ul>
                <li>Esconderá do catálogo, caso a data seja futura.</li>
                <li>Exibirá uma badge <b>Lançamento</b> no catálogo, caso essa data seja os últimos 30 dias.</li>
              </ul>
            `}
          />

          {['SPECIALIZATION', 'COURSE'].includes(contentType) && (
            <Input
              label="Carga horária"
              type="number"
              min="1"
              name="workload"
              description="Exibido na listagem do catálogo, essa informação precisa considerar tudo, desafios, aulas, todo tipo de conteúdo."
            />
          )}
        </section>

        {['SPECIALIZATION', 'COURSE'].includes(contentType) && (
          <fieldset>
            <legend>Configurações da Jornada</legend>

            <section>
              {['SPECIALIZATION', 'COURSE'].includes(contentType) && (
                <Check
                  description="Exibe banner na página da jornada comunicando que a trilha ainda está sendo gravada e de quem em breve terá novos conteúdos."
                  label="Está sendo gravada?"
                  name="is_recording"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />
              )}

              {['SPECIALIZATION'].includes(contentType) ? (
                <Dropdown
                  name="related_journey_id"
                  label="Jornada Relacionada"
                  options={journeysList}
                  disabled={journeysListLoading}
                  placeholder="Selecione o fórum dessa jornada, caso exista."
                  isClearable
                  description={`
                  <p>A jornada relacionada serve para que no frontend possamos criar um link entre as jornadas novas e antigas</p>

                  <p>Exemplo:</p>
                  <p>Na jornada nova de <b>React JS</b> eu relaciono com a jornada antiga e no frontend aparecerá o link e vice e versa.</p>

                  <em>P.S: Quem controla se essa jornada exibirá como "Jornada Atualizada" ou "Jornada antiga" é o campo "<b>Conteúdo antigo</b>"</em>
                `}
                />
              ) : (
                <div />
              )}
            </section>

            <section>
              {['SPECIALIZATION', 'COURSE'].includes(contentType) && (
                <>
                  <Check
                    description={`
                Essa flag habilita a possibilidade de certificação, ela tem
                comportamento

                <ul>
                  <li><b>Formação:</b> Na página da jornada exibe/esconde o botão de certificação.</li>
                  <li><b>Cursos:</b> Na página da sala de aula exibe/esconde o botão de emitir certificado.</li>
                </ul>

                <em>P.S: É importante garantir que o certificado esteja configurado no serviço de certificados antes de habilitar.</em>
              `}
                    label="É certificável"
                    name="is_certificable"
                    options={[
                      { value: true, label: 'Sim' },
                      { value: false, label: 'Não' },
                    ]}
                  />

                  <Check
                    description="Exibe o trial como bônus da jornada."
                    label="Tem o trial como bônus?"
                    name="has_trial_as_bonus"
                    options={[
                      { value: true, label: 'Sim' },
                      { value: false, label: 'Não' },
                    ]}
                  />
                </>
              )}

              {['COURSE'].includes(contentType) ? (
                <Check
                  description="Essa flag permite que quando esse conteúdo for comprado, ele dará direito a um desconto de R$ 297,00 (Utilizado apenas para cursos)"
                  label="Essa jornada tem cashback?"
                  name="has_cashback"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />
              ) : (
                <div />
              )}
            </section>

            {['SPECIALIZATION', 'COURSE'].includes(contentType) && (
              <section>
                <Dropdown
                  isClearable
                  load={loadOffers}
                  name="pluto_offer_slug"
                  label="Oferta relacionada"
                  placeholder="Selecione a oferta relacionada a essa jornada."
                  getOptionLabel={(option) =>
                    `[${option?.slug}] ${option.title}`
                  }
                />
              </section>
            )}
          </fieldset>
        )}

        <fieldset>
          <legend>Configurações do Catálogo</legend>

          <section>
            <Check
              description="Faz com que a jornada seja exibida no catálogo, caso esteja marcado como não, ela não exibe."
              label="Exibir no catálogo"
              name="is_enabled_on_catalog"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              onClick={(checked) => setIsEnabledOnCatalog(checked.value)}
            />

            <Check
              description="Caso seja um conteúdo antigo, esse conteúdo deixa de ser exibido em certos lugares."
              label="Conteúdo antigo"
              name="is_old"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              onClick={(checked) => setIsOld(checked.value)}
            />
          </section>

          <section>
            <Check
              description="Exibe a badge de gratuito no catálogo."
              label="Conteúdo gratuito"
              name="is_free"
              onClick={(checked) => setIsFree(checked.value)}
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
            />

            {isFree && (
              <Datepicker
                label="Data fim do periodo gratuito"
                name="free_until"
                showTimeSelect
                timeIntervals={5}
                description={`
              Data fim em que a jornada ficará gratuita para os usuários.

              <li>Observação: apague a data, caso queira remover o período de gratuidade!</li>
            `}
              />
            )}

            <Check
              description="Exibe a badge de oferta no catálogo."
              label="É uma oferta?"
              name="is_offer"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
            />
          </section>

          <section>
            <Check
              description="Usuário terá acesso a jornada após o final do seu acesso."
              label="Acesso estendido"
              name="has_after_access"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
            />

            <Input
              label="Link da LP"
              min="1"
              name="landing_page_url"
              placeholder="Insira o link da LP"
              description={`
              <p>Esse é o link para qual o usuário será redirecionado ao clicar no botão de saiba mais no catálogo.</p>

              <strong>⚠️ Não precisa adicionar UTM, já está configurado na plataforma.</strong>
            `}
            />
          </section>

          <section>
            {contentType === 'COURSE' && (
              <>
                <Input
                  label="Link da Oferta"
                  min="1"
                  name="offer_url"
                  placeholder="Insira o link da oferta"
                  description={`
                  <p>Esse é o link para qual o usuário será redirecionado ao clicar na oferta do catálogo.</p>

                  <strong>⚠️ Não precisa adicionar UTM, já está configurado na plataforma.</strong>
                `}
                />

                <Check
                  description={`
                    Curso passará a obter as informações diretamente da jornada e não mais do node.

                    <ul>
                      <li>Título</li>
                      <li>Descrição</li>
                      <li>Slug</li>
                    </ul>
                    `}
                  label="Deve obter informações da jornada?"
                  name="should_redirect_to_internal_page"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />
              </>
            )}
          </section>

          <section>
            {!isEnabledOnCatalog && (
              <Check
                description="Exibe uma jornada apenas para quem tem acesso a ela de forma ativa."
                label="Visível no catálogo apenas para quem tem acesso"
                name="is_visible_for_users_with_access"
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
              />
            )}

            <div />
          </section>
        </fieldset>

        <fieldset>
          <legend>Configurações do Catálogo</legend>

          <section>
            <Dropdown
              label="Circle Workspace (Apenas caso essa jornada seja integrada com circle)"
              name="circle_workspace_id"
              placeholder="Selecione a workspace da comunidade"
              load={loadWorkspaces}
            />
          </section>
        </fieldset>

        <fieldset>
          <legend>Configurações da busca</legend>

          <section>
            <Check
              description="Esse campo define se os conteúdos dessa jornada podem ou não ser encontrados na busca da plataforma."
              label="É pesquisável?"
              name="is_searchable"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
            />

            <Check
              description="Esse campo define se essa jornada aparecerá nos filtros da busca."
              label="É filtrável?"
              name="is_filterable"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
            />
          </section>
        </fieldset>

        <TagForms tags={journey.contentTags} />

        <fieldset style={{ marginTop: 20 }}>
          <legend>Dados de CSAT</legend>

          <section>
            <Input
              label="Nota média"
              name="csat_score"
              type="text"
              description="Define a nota da jornada, com base nas avaliações dos alunos. Deve ser um número entre 0 e 5."
            />

            <Input
              label="Quantidade de reviews"
              name="csat_reviews"
              type="text"
              description="Define a quantidade de reviews considerados para determinar a nota média da jornada."
            />
          </section>
        </fieldset>

        <TestimonialForm
          testimonials={testimonials}
          onSubmit={handleAddTestimonial}
        />

        <section>
          <Dropdown label="Times" name="teams" multiple load={loadTeams} />
        </section>

        <Button type="submit" style={{ marginTop: 30 }}>
          Salvar node
        </Button>
      </Form>
    );
  }, [
    journey,
    updateJourney,
    clearJourneyProgressCache,
    isCleaningJourneyProgressCache,
    clearJourneyCache,
    isCleaningJourneyCache,
    reindexContent,
    isReindexingJourneyContent,
    syncLessons,
    isSyncingLessons,
    contentType,
    isEntryCourse,
    educators,
    isLoadingEducators,
    forums,
    isLoadingFormData,
    loadLessons,
    termsOfUseList,
    termsOfUseLoading,
    journeysList,
    journeysListLoading,
    loadOffers,
    isFree,
    isEnabledOnCatalog,
    testimonials,
    handleAddTestimonial,
  ]);

  return render;
}
