import styled, { keyframes } from 'styled-components';

const colors = {
  cancel: '#CACACA',
  default: '#7159c1',
  danger: '#DD5554',
  warning: '#DDCC54',
  success: '#4FCDA5',
};

const sizes = {
  default: `
    padding: 0 20px;
    font-size: 14px;
    height: 36px;
  `,
  'extra-small': `
    padding: 0 10px;
    font-size: 12px;
    height: 22px;
  `,
  small: `
    padding: 0 14px;
    font-size: 13px;
    height: 28px;
  `,
  big: `
    padding: 0 25px;
    font-size: 16px;
    height: 40px;
  `,
  block: `
    font-size: 16px;
    padding: 0 25px;
    align-self: stretch;
    width: 100%;
    justify-content: center;
    height: 46px;
  `,
};

export const Container = styled.button`
  position: relative;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  border: 0;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    opacity: 0.8;
  }

  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
  }

  ${(props) => sizes[props.size]};
  ${(props) => `background: ${colors[props.color]}`};
  ${({ outline, color = 'default' }) =>
    outline &&
    `
    background: none;
    border: 1px solid ${colors[color]};
    color: ${colors[color]};
  `}
`;

const loadingAnimation = keyframes`
  0%, 100% {
    transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
  }
`;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.span`
  position: absolute;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: inherit;
  border-radius: inherit;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;

    animation: ${spin} 1s linear infinite;
  }
`;
