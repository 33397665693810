import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { MdClose, MdChangeCircle } from 'react-icons/md';
import { FormProvider } from 'react-hook-form';
import SweetAlert2 from 'react-sweetalert2';
import { toast } from 'react-toastify';

import { PurchaseLicenses } from '../List/PurchaseLicenses';
import { usePurchaseForm } from '../purchase-form.hook';
import { useOfferForm } from '../offer-form.hook';
import { TeamsUsersForm } from './TeamUsers';

import { Dropdown, Input, Datepicker, Check } from '~/components/HookForm';
import { Container } from '~/components/HookForm/Form/styles';
import Button from '~/components/Button';
import Page from '~/components/Page';
import { useCan } from '~/utils';

function UsersPurchasesForm({ match, history, location }) {
  const can = useCan();
  const viewOnly = !can('edit_purchases');

  const {
    plans,
    teams,
    purchase,
    isUpdating,
    purchaseForm,
    handlePurchaseSubmit,
  } = usePurchaseForm({
    location,
    match,
  });

  const {
    offers,
    loadingOffers,
    offerChangeForm,
    showOfferChangeModal,
    setShowOfferChangeModal,
    handleOfferChangeRequest,
  } = useOfferForm({ history, purchase });

  const shouldHighlightProduct = useMemo(
    () => new URLSearchParams(location.search).get('highlight') === 'product',
    [location.search],
  );

  return (
    <Page loading={purchaseForm.formState.isLoading || loadingOffers}>
      <header>
        {viewOnly ? (
          <h1>Visualizar compra</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Nova'} compra`}</h1>
        )}

        <div className="flex gap-2 justify-end">
          <Button
            icon={MdChangeCircle}
            onClick={() => setShowOfferChangeModal(true)}
          >
            Trocar oferta
          </Button>

          <Button icon={MdClose} color="cancel" onClick={history.goBack}>
            Cancelar
          </Button>
        </div>
      </header>

      <Container>
        <FormProvider {...purchaseForm}>
          <form
            onSubmit={purchaseForm.handleSubmit(handlePurchaseSubmit)}
            className="flex flex-col gap-2"
          >
            <section>
              <Input label="Transação" name="transaction" readOnly={viewOnly} />

              <Input
                label="Transação Externa"
                name="transaction_ext"
                readOnly={viewOnly}
              />

              <Input
                label="Engine de Pagamento"
                name="payment_engine"
                readOnly={viewOnly}
              />

              <Input
                label="Tipo de Pagamento"
                name="payment_type"
                readOnly={viewOnly}
              />
            </section>

            <section>
              <Input label="Cupom" name="coupon" readOnly={viewOnly} />

              <Input
                label="Produto"
                name="product"
                readOnly={viewOnly}
                style={{
                  borderColor: shouldHighlightProduct ? '#7159c1' : 'inherit',
                  borderWidth: '2px',
                }}
              />

              <Input label="Off" name="off" readOnly={viewOnly} />

              <Input label="Status" name="status" readOnly={viewOnly} />
            </section>

            <section>
              <Datepicker
                label="Data inicio"
                name="start_date"
                readOnly={viewOnly}
              />

              <Datepicker
                label="Data fim"
                name="end_date"
                readOnly={viewOnly}
              />
            </section>

            <section>
              <Input
                label="Valor"
                name="price"
                type="number"
                readOnly={viewOnly}
              />

              <Input label="Moeda" name="currency" readOnly={viewOnly} />

              <Input label="Referência" name="referral" readOnly={viewOnly} />
            </section>

            <section>
              <Datepicker
                label="Data da Garantia"
                name="warranty_date"
                disabled={viewOnly}
              />

              <Datepicker
                label="Data de Cancelamento"
                name="canceled_at"
                disabled={viewOnly}
              />

              <Datepicker
                label="Data de Reembolso"
                name="refunded_at"
                disabled={viewOnly}
              />
            </section>

            <section>
              <Dropdown
                label="Time"
                name="team"
                isClearable
                options={teams.map((team) => ({
                  value: team.id,
                  label: team.title,
                }))}
                disabled={viewOnly}
              />

              <Dropdown
                label="Plano"
                name="plan"
                isClearable
                options={plans.map((team) => ({
                  value: team.id,
                  label: team.title,
                }))}
                disabled={viewOnly}
              />
            </section>

            <section>
              <Check
                label="Recebeu Upgrade do One?"
                name="is_one_upgraded"
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                readOnly={viewOnly}
              />

              <Check
                label="É uma recorrencia?"
                name="is_recurrence"
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                readOnly={viewOnly}
              />
            </section>

            <section>
              <Input
                label="ID da Oferta no Pluto"
                name="pluto_offer_id"
                readOnly={viewOnly}
              />
            </section>

            <Input label="Metadata" name="metadata" multiline />

            <section />

            {!viewOnly && (
              <Button
                type="submit"
                size="block"
                loading={purchaseForm.formState.isSubmitting}
              >
                Salvar
              </Button>
            )}
          </form>

          {isUpdating && (
            <TeamsUsersForm
              purchaseId={purchase.id}
              userId={purchase.user_id}
              teams={purchase.teams}
              viewOnly={viewOnly}
            />
          )}

          {purchase.licenses && (
            <PurchaseLicenses licenses={purchase.licenses} />
          )}
        </FormProvider>

        <SweetAlert2
          show={showOfferChangeModal}
          title="Trocar oferta"
          icon="warning"
          showCancelButton
          showConfirmButton
          confirmButtonText="Trocar"
          cancelButtonText="Cancelar"
          preConfirm={() => {
            const newOffer = offerChangeForm.getValues('new_offer');

            if (!newOffer?.id) {
              toast.error('Selecione uma nova oferta para efetuar a troca!');
              return false;
            }
          }}
          onConfirm={() => handleOfferChangeRequest(purchase)}
          onResolve={() => {
            setShowOfferChangeModal(false);
          }}
          width="50rem"
          customClass={{
            htmlContainer: 'min-h-[17.5rem]',
          }}
        >
          <FormProvider {...offerChangeForm}>
            <section className="flex flex-col gap-4">
              <Input
                label="Oferta atual"
                name="current_offer"
                className="min-h-12"
                defaultValue={purchase.product}
                readOnly
              />

              <Dropdown
                label="Nova oferta"
                name="new_offer"
                className="min-h-12 text-left"
                placeholder="Selecione a nova oferta"
                options={offers}
                disabled={loadingOffers || viewOnly}
              />
            </section>
          </FormProvider>
        </SweetAlert2>
      </Container>
    </Page>
  );
}

UsersPurchasesForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,

  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
};

UsersPurchasesForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default UsersPurchasesForm;
