import { useCallback } from 'react';

/**
 * @typedef {Object} Challenge
 * @property {string} type - The type of the challenge (e.g., 'quiz', 'leveling').
 * @property {boolean} [has_attempts] - Indicates if the challenge has attempts available.
 * @property {any} [otherProps] - Other properties related to the challenge.
 */

/**
 * Defines the visibility conditions for various components based on challenge type and other properties.
 * @type {Object.<string, (challenge: Challenge) => boolean>}
 */
const visibilityConditions = {
  form_id: ({ type }) => ['quiz', 'leveling'].includes(type),
  details_divider: ({ type }) => ['quiz', 'leveling'].includes(type),
  has_attempts: ({ type }) => type === 'quiz',
  show_response: ({ type }) => ['quiz', 'leveling'].includes(type),
  attempts: ({ type, has_attempts }) => type === 'quiz' && !!has_attempts,
  is_evaluative: ({ type }) => type === 'quiz',
  blocked_challenge_id: ({ type }) => type === 'quiz',
  'leveling.score': ({ type }) => type === 'leveling',
  'leveling.journeyId': ({ type }) => type === 'leveling',
  'leveling.nodeIds': ({ type }) => type === 'leveling',
};

/**
 * Custom hook for managing visibility and loading conditions in challenge forms.
 * It adapts to different challenge types (e.g., 'quiz', 'leveling') based on challenge properties.
 *
 * @param {Challenge} challenge - The challenge object that contains information about the challenge.
 *
 * @returns {{
 *   canShow: (componentNames: string | string[]) => boolean,
 *   canLoadForms: (type: string) => boolean,
 *   canLoadJourneys: (type: string) => boolean
 * }}
 */
export function useChallengeForm(challenge) {
  const canShow = useCallback(
    (componentNames) => {
      const names = Array.isArray(componentNames)
        ? componentNames
        : [componentNames];

      return names.every((name) => {
        const condition = visibilityConditions[name];

        if (!condition) {
          return false;
        }

        return typeof condition === 'function'
          ? condition(challenge)
          : condition;
      });
    },
    [challenge],
  );

  const canLoadForms = useCallback((type) => {
    return ['quiz', 'leveling'].includes(type);
  }, []);

  const canLoadJourneys = useCallback((type) => {
    return ['leveling'].includes(type);
  }, []);

  return { canShow, canLoadForms, canLoadJourneys };
}
