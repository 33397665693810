import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { MdSearch, MdAdd } from 'react-icons/md';

import api from '~/services/certificates.api';
import { Page } from '~/pages/styles';
import { SearchForm, Thumbnail } from '~/components/PagedList/styles';
import { Input } from '~/components/Form';
import Button from '~/components/Button';
import { useCan } from '~/utils';
import { Table, Actions } from '~/components/Table';
import Pagination from '~/components/Pagination';
import SpinnerLoading from '~/components/SpinnerLoading';

function EducatorsList() {
  const [loading, setLoading] = useState(false);
  const [educators, setEducators] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const itemsPerPage = 20;
  const can = useCan();

  useEffect(() => {
    async function loadEducators() {
      try {
        setLoading(true);

        const { data: response } = await api.get('/educators', {
          params: {
            page: currentPage,
            search,
          },
        });

        setEducators(response.educators);
        setTotalPages(Math.ceil(response.totalCount / itemsPerPage));
        setTotalCount(response.totalCount);
      } catch (err) {
        const message = err?.response?.data?.message;

        toast.error(
          `Erro ao buscar educadores${message ? `: ${message}` : '.'}`,
        );
      } finally {
        setLoading(false);
      }
    }

    loadEducators();
  }, [currentPage, search]);

  function handlePaginate(newPage) {
    setCurrentPage(newPage);
  }

  function handleSearch(e) {
    e.preventDefault();
    handlePaginate(1);
  }

  function renderHeader() {
    return (
      <header>
        <h1>Lista de Educadores</h1>

        <div>
          <SearchForm onSubmit={handleSearch}>
            <Input
              type="text"
              placeholder="Digite sua busca..."
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              unform={false}
            />
            <Button type="submit" icon={MdSearch} color="success" />
          </SearchForm>

          {can(`create_certificates`) && (
            <Button
              icon={MdAdd}
              color="success"
              to="/certificate-service/educator/new"
            >
              Novo
            </Button>
          )}
        </div>
      </header>
    );
  }

  function renderBody() {
    return (
      <Table loading={loading}>
        <thead>
          <tr>
            <th>Avatar</th>
            <th>Nome</th>
            <th>Slug</th>
          </tr>
        </thead>
        <tbody>
          {educators.map((educator) => (
            <tr key={educator.id}>
              <td>
                {educator.avatarUrl && (
                  <Thumbnail>
                    <img src={educator.avatarUrl} alt="" />
                  </Thumbnail>
                )}
              </td>
              <td>{educator.name}</td>
              <td>{educator.slug}</td>
              <td>
                <Actions
                  href={`/certificate-service/educator/edit/${educator.id}`}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <Page>
      {renderHeader()}

      {loading && <SpinnerLoading />}

      {!loading && educators.length === 0 && <p>Nenhum educador encontrado.</p>}

      {!loading && educators.length > 0 && renderBody()}

      <Pagination
        activePage={currentPage}
        pageCount={totalPages}
        total={totalCount}
        perPage={itemsPerPage}
        onChange={handlePaginate}
      />
    </Page>
  );
}

EducatorsList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default EducatorsList;
