/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import debounce from 'debounce-promise';
import { get } from 'dot-prop-immutable';

import JourneyContext from '../context';
import NodeItem from '../NodeItem';
import schema from './schema';

import {
  Form,
  Input,
  Check,
  Dropdown,
  Datepicker,
  FileInput,
} from '~/components/Form';
import Button from '~/components/Button';
import api from '~/services/api';
import { TagForms } from '~/components/TagForms';

export default function NodeForm({ node, journey }) {
  const { lessonGroups, clusters, challenges, updateNode, isLoadingFormData } =
    useContext(JourneyContext);

  const [type, setType] = useState(node.type);
  const [microCertificates, setMicroCertificates] = useState([]);

  const nodeTypes = useMemo(() => {
    return ['EXTRA'].includes(journey.content_type)
      ? [{ id: 'group', title: 'Grupo de aulas' }]
      : [
          { id: 'cluster', title: 'Cluster' },
          { id: 'lesson', title: 'Aula' },
          { id: 'group', title: 'Grupo de aulas' },
          { id: 'challenge', title: 'Desafio' },
          { id: 'micro-certificate', title: 'Micro certificado' },
          { id: 'subject', title: 'Jornada' },
        ];
  }, []);

  const handleSubmit = useCallback((data) => {
    updateNode({ ...node, ...data });
  }, []);

  const loadMicroCertificates = useCallback(async (journeyId) => {
    const { data: response } = await api.get('/micro-certificates', {
      params: {
        journey_id: journeyId,
      },
    });

    setMicroCertificates(response.data);
  }, []);

  useEffect(() => {
    loadMicroCertificates(journey.id);
  }, []);

  function renderDropdown() {
    let label = 'Aulas';
    let options = [];
    let name = 'lesson_id';
    let async = false;
    let loadOptions = null;
    let loadDefaultValue = null;
    let placeholder = 'Selecione uma aula';

    switch (type) {
      case 'group':
        name = 'lesson_group_id';
        label = 'Grupo de aulas';
        options = lessonGroups;
        placeholder = 'Selecione um grupo de aulas';
        break;
      case 'cluster':
        name = 'cluster_id';
        label = 'Cluster';
        options = clusters;
        placeholder = 'Selecione um cluster';
        break;
      case 'challenge':
        name = 'challenge_id';
        label = 'Desafio';
        options = challenges;
        placeholder = 'Selecione um desafio';
        break;
      case 'micro-certificate':
        name = 'micro_certificate_id';
        label = 'Micro certificado';
        options = microCertificates;
        placeholder = 'Selecione um micro certificado';
        break;
      case 'subject':
        name = 'related_journey_id';
        label = 'Jornada';
        placeholder = 'Selecione uma jornada';
        async = true;
        loadDefaultValue = async (journeyId) => {
          const response = await api.get(`/journeys/${journeyId}`);

          return response.data;
        };
        loadOptions = debounce(async (search) => {
          const { data: response } = await api.get('/journeys', {
            params: { search },
          });

          return response.data;
        }, 500);

        break;
      default:
        async = true;
        loadDefaultValue = async (lessonId) => {
          const response = await api.get(`/lessons/${lessonId}`);

          return response.data;
        };
        loadOptions = debounce(
          async (search) => {
            const response = await api.get('/lessons', {
              params: {
                search,
              },
            });

            return (
              response.data?.data.map((lesson) => ({
                ...lesson,
                title: get(lesson, 'history.$end.tagged_title'),
              })) || []
            );
          },
          500,
          { leading: true },
        );
    }

    return (
      <Dropdown
        label={label}
        name={name}
        options={options}
        loadOptions={loadOptions}
        disabled={isLoadingFormData}
        async={async}
        loadDefaultValue={loadDefaultValue}
        placeholder={placeholder}
      />
    );
  }

  const render = useMemo(() => {
    return (
      <Form schema={schema} initialData={node} onSubmit={handleSubmit}>
        <section>
          <FileInput
            name="thumbnail"
            placeholder="Selecione uma thumbnail"
            path="platform"
          />
        </section>

        <section>
          <Dropdown
            label="Tipo de node"
            name="type"
            placeholder="Selecione o tipo de conteúdo da node"
            options={nodeTypes}
            onChange={(selected) => setType(selected.id)}
          />

          {renderDropdown()}
        </section>

        <section>
          <Input unform label="Título" name="title" />
          <Input
            unform
            label="Slug"
            name="slug"
            description="⚠️ Tenha certeza do que você está fazendo."
          />
        </section>

        <section>
          <Input
            unform
            label="Label"
            name="subtitle"
            description="Este campo é exibido na página da jornada, antes do título."
          />
        </section>

        {['SPECIALIZATION', 'COURSE', 'COLLECTION'].includes(
          journey.content_type,
        ) && (
          <>
            <section>
              <Input
                unform
                label="Descrição da Milestone"
                name="milestone_description"
                multiline
              />
            </section>

            <section>
              <Input unform label="Descrição" name="description" multiline />
            </section>
          </>
        )}

        <fieldset>
          <legend>Configuração da Liberação</legend>

          <section>
            <section>
              <Datepicker
                label="Data de liberação"
                name="release_at"
                showTimeSelect
              />
            </section>

            <Check
              label="Habilitado"
              name="active"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              defaultValue={{ value: false, label: 'Não' }}
            />
          </section>

          <section>
            <Check
              description="Usuário terá acesso a node após o final do seu acesso."
              label="Acesso estendido"
              name="has_after_access"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
            />

            <Check
              description="Se marcado como sim, mesmo que o conteúdo não estiver disponível, será exibido informações como quantidade de aulas, testes e etc..."
              label="Mostrar informações"
              name="show_info"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
            />
          </section>
        </fieldset>

        {['SPECIALIZATION', 'COURSE'].includes(journey.content_type) && (
          <fieldset>
            <legend>Configuração da Visualização</legend>

            <section>
              <Dropdown
                label="Layout"
                name="layout"
                options={[
                  { id: 'button', title: 'Button' },
                  { id: 'challenge', title: 'Challenge' },
                  { id: 'embed', title: 'Embed' },
                  { id: 'horizontal-card', title: 'Horizontal card' },
                  { id: 'vertical-card', title: 'Vertical card' },
                  { id: 'lesson-modal', title: 'Lesson modal' },
                  { id: 'micro-certificate', title: 'Micro Certificate' },
                ]}
              />

              <Check
                label="É uma milestone"
                name="is_milestone"
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                defaultValue={{ value: false, label: 'Não' }}
                description={`
                  Esse campo cria um agrupamento entre ele e as próximas nodes até a próxima milestone.
                `}
              />
            </section>

            <section>
              <Check
                label="É uma Premiere?"
                name="premiere"
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                defaultValue={{ value: false, label: 'Não' }}
              />

              <Check
                label="É um modulo?"
                name="module"
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                defaultValue={{ value: false, label: 'Não' }}
                description="⚠️ Após o catálogo, esse campo será depreciado."
              />
            </section>

            <section>
              <Check
                label="É um bônus de jornada completa"
                description="Essa node só será exibida quando o usuário concluir a jornada."
                name="is_bonus"
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                defaultValue={{ value: false, label: 'Não' }}
              />

              <Check
                label="É introdutório"
                name="introductory"
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                defaultValue={{ value: false, label: 'Não' }}
                description={`
                  Essa flag define se o conteúdo é exibido antes da jornada ser iniciada.

                  ⚠️ Após o catálogo, esse campo será depreciado.
                `}
              />
            </section>
          </fieldset>
        )}

        {['EXTRA'].includes(journey.content_type) && (
          <fieldset>
            <legend>Configuração de conteúdos complementares</legend>

            <section>
              <Check
                label="Deve exibir aula individualmente"
                name="is_single_class_view"
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                defaultValue={{ value: false, label: 'Não' }}
                description={`
                  Quando selecionado essa flag, o conteúdo sera exibido de forma individual no catálog, ou seja, não aparecerá como um grupo de aulas, será exibido e carregado na sala de aula de forma individual.
                `}
              />
            </section>
          </fieldset>
        )}

        <TagForms />

        <Button type="submit">Salvar node</Button>
      </Form>
    );
  }, [node, handleSubmit, nodeTypes, renderDropdown, journey.content_type]);

  return render;
}

NodeForm.propTypes = {
  node: NodeItem.propTypes.node,
};
