/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Trash } from 'lucide-react';
import { z } from 'zod';

import Button from '~/components/Button';
import Page from '~/components/Page';
import api from '~/services/api';
import { Input, Check } from '~/components/HookForm';
import { cn } from '~/lib/utils';

const schema = z.object({
  name: z.string(),
  admin_api_token: z.string(),
  headless_api_token: z.string(),
  url: z.string(),
  integrations: z
    .object({
      id: z.string().optional(),
      resource: z.string(),
      resource_id: z.string(),
      space_ids: z.string().optional().nullable(),
      space_group_ids: z.string().optional().nullable(),
      member_tag_ids: z.string().optional().nullable(),
      _destroy: z.boolean().optional(),
    })
    .array(),
});

const Form = ({ data, history }) => {
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      ...data,
      integrations: data?.integrations?.map((item) => ({
        ...item,
        space_ids: item.space_ids ? item.space_ids?.join(',') : null,
        space_group_ids: item.space_group_ids
          ? item.space_group_ids?.join(',')
          : null,
        member_tag_ids: item.member_tag_ids
          ? item.member_tag_ids?.join(',')
          : null,
      })),
    },
  });

  const { handleSubmit, control } = form;
  const { fields, append, update } = useFieldArray({
    name: 'integrations',
    control,
    keyName: '_id',
  });

  const onSubmit = useCallback(
    async (formData) => {
      try {
        const id = data?.id;

        await api.postOrPut('/circle/workspaces', id, formData);

        toast.success('Workspace salva com sucesso!');
        history.push('/circle/workspaces');
      } catch (err) {
        toast.error('Algo deu errado.');
      }
    },
    [data?.id, history],
  );

  const handleDeleteField = useCallback(
    (index) => {
      const field = fields[index];

      update(index, {
        ...field,
        _destroy: true,
      });
    },
    [fields, update],
  );

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="py-4 flex flex-col gap-4"
      >
        <div className="flex justify-between gap-4">
          <div className="w-full flex flex-col gap-1">
            <label className="font-semibold">Nome da workspace</label>
            <Input name="name" placeholder="Faculdade de tecnologia" />
          </div>

          <div className="w-full flex flex-col gap-1">
            <label className="font-semibold">URL da comunidade</label>
            <Input name="url" placeholder="URL da comunidade" />
          </div>
        </div>

        <div className="flex justify-between gap-4">
          <div className="w-full flex flex-col gap-1">
            <label className="font-semibold">Admin API Token</label>
            <Input name="admin_api_token" placeholder="Admin API Token" />
            <small>
              {`Você pode encontrar o API Token, acessando a plataforma do Circle
        em Configurações > Tokens`}
            </small>
          </div>

          <div className="w-full flex flex-col gap-1">
            <label className="font-semibold">Headless API Token</label>
            <Input name="headless_api_token" placeholder="Headless API Token" />
            <small>
              {`Você pode encontrar o API Token, acessando a plataforma do Circle
        em Configurações > Tokens`}
            </small>
          </div>
        </div>

        <fieldset className="border border-gray-300 p-4 rounded">
          <legend className="text-lg font-semibold px-2">Integrações</legend>

          {fields.map((field, fieldIndex) => (
            <div
              key={field._id}
              className={cn(field._destroy ? 'opacity-35' : '')}
            >
              <Input type="hidden" name={`integrations.${fieldIndex}.id`} />
              <Input
                type="hidden"
                name={`integrations.${fieldIndex}._destroy`}
              />

              <div className="flex gap-4 mb-4">
                <div className="w-full flex flex-col gap-1">
                  <Check
                    label="Integrar por"
                    name={`integrations.${fieldIndex}.resource`}
                    options={[
                      { value: 'Contract', label: 'Contrato' },
                      { value: 'Team', label: 'Time' },
                    ]}
                  />
                </div>

                <div className="w-full flex flex-col gap-1">
                  <label className="font-semibold">ID do Recurso</label>
                  <Input
                    name={`integrations.${fieldIndex}.resource_id`}
                    placeholder="ID do Contrato ou Time"
                  />
                </div>

                <div className="w-full flex flex-col gap-1">
                  <label className="font-semibold">ID dos Espaços</label>
                  <Input
                    name={`integrations.${fieldIndex}.space_ids`}
                    placeholder="1,2,3 (Separador por vírgula)"
                  />
                </div>

                <div className="w-full flex flex-col gap-1">
                  <label className="font-semibold">
                    ID dos grupos de espaços
                  </label>
                  <Input
                    name={`integrations.${fieldIndex}.space_group_ids`}
                    placeholder="1,2,3 (Separador por vírgula)"
                  />
                </div>

                <div className="w-full flex flex-col gap-1">
                  <label className="font-semibold">ID das tags de mebros</label>
                  <Input
                    name={`integrations.${fieldIndex}.member_tag_ids`}
                    placeholder="1,2,3 (Separador por vírgula)"
                  />
                </div>

                <Button
                  type="button"
                  onClick={() => handleDeleteField(fieldIndex)}
                  className="self-end"
                >
                  <Trash className="size-4" />
                </Button>
              </div>
            </div>
          ))}

          <Button type="button" onClick={append}>
            Adicionar
          </Button>
        </fieldset>

        <Button
          type="submit"
          className="text-center items-center flex justify-center"
        >
          Salvar
        </Button>
      </form>
    </FormProvider>
  );
};

export default function CircleWorkspacesForm({ match, history }) {
  const id = match?.params?.id;
  const isEditing = !!id;

  const [data, setData] = useState({});
  const [spaces, setSpaces] = useState([]);
  const [spaceGroups, setSpaceGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(isEditing);

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: response } = await api.get(`/circle/workspaces/${id}`);

      setData(response);
    } catch (err) {
      toast.error('Não foi possível carregar o workspace');
      history.push('/circle/workspaces');
    } finally {
      setIsLoading(false);
    }
  }, [history, id]);

  const loadSpaces = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: response } = await api.get(
        `/circle/workspaces/${id}/spaces`,
      );

      setSpaces(response.records);
    } catch (err) {
      toast.error('Não foi possível carregar o workspace');
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  const loadSpaceGroups = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: response } = await api.get(
        `/circle/workspaces/${id}/space-groups`,
      );

      setSpaceGroups(response.records);
    } catch (err) {
      toast.error('Não foi possível carregar o workspace');
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (isEditing) {
      loadData();
      loadSpaces();
      loadSpaceGroups();
    }
  }, [isEditing, loadData, loadSpaceGroups, loadSpaces]);

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isEditing ? 'Editar' : 'Nova'} Workspace ${data?.name}`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/circle/workspaces">
            Cancelar
          </Button>
        </div>
      </header>

      {!isLoading && <Form data={data} history={history} />}

      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col gap-4">
          <h3>Spaces</h3>
          <table className="border">
            <thead>
              <tr className="border">
                <th className="text-left px-2 py-1">Nome</th>
                <th className="text-left px-2 py-1">ID</th>
              </tr>
            </thead>
            <tbody>
              {spaces.map((space) => (
                <tr className="border">
                  <td className="text-left px-2 py-1">{space.name}</td>
                  <td className="text-left px-2 py-1">{space.id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex flex-col gap-4">
          <h3>Space Groups</h3>
          <table>
            <thead>
              <tr className="border">
                <th className="text-left px-2 py-1">Nome</th>
                <th className="text-left px-2 py-1">ID</th>
              </tr>
            </thead>
            <tbody>
              {spaceGroups.map((spaceGroup) => (
                <tr className="border">
                  <td className="text-left px-2 py-1">{spaceGroup.name}</td>
                  <td className="text-left px-2 py-1">{spaceGroup.id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
}

CircleWorkspacesForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
