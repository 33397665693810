import * as Yup from 'yup';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório'),
  slug: Yup.string(),
  start_at: Yup.string().nullable(),
  end_at: Yup.string().nullable(),
  required: Yup.boolean().nullable(),
  instruction: Yup.string(),
  form_id: Yup.string(),
  type: Yup.string(),
  description: Yup.string(),
  resolution_url: Yup.string(),
  blocked_challenge_id: Yup.string(),
  has_attempts: Yup.boolean(),
  show_response: Yup.boolean(),
  is_evaluative: Yup.boolean(),
  attempts: Yup.string().when('has_attempts', {
    is: true,
    then: Yup.string().required('campo obrigatório.'),
  }),
  leveling: Yup.object().when('type', {
    is: 'leveling',
    then: (leveling) =>
      leveling.shape({
        score: Yup.number()
          .transform((c, o) => (o === '' ? null : c))
          .nullable()
          .test(
            'is-valid-score',
            'Nota mínima deve ser entre 0 e 10',
            (value) => !!value || (value >= 0 && value <= 10),
          )
          .required('Informe uma nota entre 0 e 10'),
        journeyId: Yup.string().required('Selecione uma jornada'),
        nodeIds: Yup.array().required('Selecione pelo menos um módulo'),
      }),
  }),
});

export default schema;
