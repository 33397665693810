/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';

import { useLazyOffers } from '@shared/skylab/graphql/pluto';
import {
  reminderFormRepeatOptions,
  reminderFormCategoryOptions,
} from './reminder-form.options';

import api from '~/services/api';
import Page from '~/components/Page';

async function getEducators(search) {
  const { data } = await api.get('/educators', {
    params: {
      search,
      per_page: 999,
    },
  });

  const educators = data.data.map((educator) => ({
    value: educator.id,
    label: educator.user.name,
  }));

  return educators;
}

async function getTagsByType(type) {
  const { data: response } = await api.get('/content-tags', {
    params: {
      type,
      perPage: 999,
    },
  });

  const tagLevels =
    response?.data?.map((tag) => ({
      value: tag.id,
      label: tag.title,
    })) || [];

  return tagLevels;
}

async function getReminder(id) {
  const { data } = await api.get(`/reminders/${id}`);

  return data;
}

async function getFeaturedReminder() {
  const { data: response } = await api.get('/reminders', {
    params: {
      featured: true,
    },
  });

  const [featuredReminder] = response.data || [];

  return featuredReminder;
}

function makeDefaultValues({ reminder, offers }) {
  const isUpdating = !!reminder?.id;

  const level = reminder?.contentTags?.find((tag) => tag.type === 'LEVEL');

  const offer = offers?.find(
    ({ value }) => value === reminder?.pluto_offer_slug,
  );

  return {
    // COMMON FIELDS FOR REMINDERS
    name: reminder.title,
    is_free: isUpdating ? !reminder?.is_free : null,
    should_display_hours: isUpdating ? !!reminder?.should_display_hours : null,
    local: reminder?.local,
    cta_link: reminder?.cta_url,
    cta_label: reminder?.cta_label,
    description: reminder?.description,
    banner: reminder?.banner,
    is_featured: isUpdating ? !!reminder?.is_featured : null,
    is_recommended: isUpdating ? !!reminder?.is_recommended : null,
    is_college: isUpdating ? !!reminder?.is_college : null,

    start_date:
      isUpdating && reminder?.starts_at
        ? new Date(reminder.starts_at)
        : undefined,

    end_date:
      isUpdating && reminder?.ends_at ? new Date(reminder.ends_at) : undefined,

    category:
      isUpdating &&
      reminderFormCategoryOptions.find(
        (category) => category.value === reminder?.type,
      ),

    // COMMON BUT SPECIFIC FOR CALENDAR REMINDER RECURRENCY
    should_repeat: isUpdating ? !!reminder?.reminderRecurrences : null,

    frequency:
      isUpdating &&
      reminderFormRepeatOptions.find(
        (option) => option.value === reminder?.reminderRecurrences?.frequency,
      ),

    recurrency_end_date:
      isUpdating && reminder?.reminderRecurrences?.end_date
        ? new Date(reminder.reminderRecurrences.end_date)
        : undefined,

    interval: isUpdating && reminder?.reminderRecurrences?.interval,

    // EVENT RELATED FIELDS
    subscribers_limit: reminder?.subscribers_limit || undefined,

    tag_skills: reminder?.contentTags
      ?.filter((tag) => tag.type === 'SKILL')
      .map((tag) => ({
        value: tag.id,
        label: tag.title,
      })),

    event: reminder?.event?.id && {
      value: reminder?.event?.id,
      label: `${reminder.event.title} (${reminder.event.slug})`,
    },

    restrict_subscriptions: isUpdating
      ? !!reminder?.restrict_subscribers
      : null,

    // EVENT AND CONTENT RELATED FIELDS
    educators: reminder?.educators?.map((educator) => ({
      value: educator.id,
      label: educator.user.name,
    })),

    technologies: reminder?.contentTags
      ?.filter((tag) => tag.type === 'TECHNOLOGY')
      .map((tag) => ({
        value: tag.id,
        label: tag.title,
      })),

    tag_level: level && {
      value: level.id,
      label: level.title,
    },

    // OFFER RELATED FIELDS
    offer,
  };
}

export const ReminderFormProvider = ({ reminderId, children }) => {
  const [fetchOffers] = useLazyOffers();
  const [offers, setOffers] = useState([]);
  const [reminder, setReminder] = useState({});
  const [educators, setEducators] = useState([]);
  const [tagLevels, setTagLevels] = useState([]);
  const [tagSkills, setTagSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [featuredReminder, setFeaturedReminder] = useState({});

  const defaultValues = useMemo(
    () =>
      makeDefaultValues({
        reminder,
        offers,
      }),
    [offers, reminder],
  );

  const getOffers = useCallback(async () => {
    const { data } = await fetchOffers();

    return (
      data?.offers?.map((offer) => ({
        value: offer.slug,
        label: `${offer.title} (${offer.slug})`,
      })) || []
    );
  }, [fetchOffers]);

  useEffect(() => {
    async function loadData() {
      try {
        setIsLoading(true);

        const [
          reminderData,
          featuredReminderData,
          offersData,
          educatorsList,
          tagLevelsData,
          tagSkillsData,
        ] = await Promise.all([
          reminderId ? getReminder(reminderId) : {},
          getFeaturedReminder(),
          getOffers(),
          getEducators(),
          getTagsByType('LEVEL'),
          getTagsByType('SKILL'),
        ]);

        setReminder(reminderData);
        setFeaturedReminder(featuredReminderData);

        setOffers(offersData);
        setEducators(educatorsList);
        setTagLevels(tagLevelsData);
        setTagSkills(tagSkillsData);
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [reminderId, fetchOffers, getOffers]);

  if (isLoading) {
    return <Page loading>Carregando</Page>;
  }

  return (
    <ReminderFormContext.Provider
      value={{
        offers,
        reminder,
        tagLevels,
        tagSkills,
        educators,
        isLoading,
        defaultValues,
        featuredReminder,
      }}
    >
      <Page loading={false}>{children}</Page>
    </ReminderFormContext.Provider>
  );
};

export const ReminderFormContext = createContext({});

export const useReminderForm = () => {
  const context = useContext(ReminderFormContext);

  if (!context) {
    throw new Error('Context not found');
  }

  return context;
};
