/* eslint-disable no-alert */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { types, products } from '../types';

import Button from '~/components/Button';
import { Form, Input, Dropdown, FileInput } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/certificates.api';

const schema = Yup.object().shape({
  title: Yup.string(),
  description: Yup.string(),
  durationInHours: Yup.number().positive(),
  product: Yup.string().oneOf([
    '',
    ...products.map((product) => product.value),
  ]),

  type: Yup.string()
    .oneOf(types.map((type) => type.value))
    .required('Type is required'),
  slug: Yup.string(),
  externalId: Yup.string(),
  logo: Yup.string(),
  badge: Yup.string(),
  icon: Yup.string(),
});

function CoursesForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [course, setCourse] = useState({
    title: '',
    description: '',
    durationInHours: 1,
    product: '',
    type: '',
    slug: '',
    externalId: '',
    logo: '',
    badge: '',
    icon: '',
  });
  const isUpdating = !!match.params.id;

  useEffect(() => {
    async function loadCourses(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/courses/${id}`);

        setCourse(response);
      } catch (err) {
        const message = err?.response?.data?.message;

        toast.error(
          `Erro ao buscar dados do curso${message ? `: ${message}` : '.'}`,
        );
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadCourses(id);
    }
  }, [match.params]);

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      if (!data.slug) {
        delete data.slug;
      }

      if (!data.externalId) {
        delete data.externalId;
      }

      if (!data.product) {
        delete data.product;
      }

      if (data.type === 'MODULE') {
        data.badge = 'Selo-Micro.svg';
      }

      if (data.type === 'SPECIALIZATION') {
        data.badge = 'Selo.svg';
      }

      if (isUpdating) {
        const { id } = match.params;

        api.put(`/courses/${id}`, data);

        toast.success('Curso atualizado com sucesso!');
      } else {
        await api.post('/courses', data);

        toast.success('Curso criado com sucesso!');
      }

      history.push('/certificate-service/courses');
    } catch (err) {
      const message = err?.response?.data?.message;
      const title = isUpdating
        ? 'Erro ao atualizar curso'
        : 'Erro ao criar curso';

      toast.error(`${title}${message ? `: ${message}` : '.'}`);

      setSubmitting(false);
    }
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} curso`}</h1>

        <div>
          <Button
            icon={MdClose}
            color="cancel"
            to="/certificate-service/courses"
          >
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={course} onSubmit={handleSubmit}>
        <section>
          <Input
            name="title"
            label="Título"
            placeholder="Título do curso"
            autoFocus
          />

          <Input
            name="slug"
            label="Slug"
            placeholder="Slug"
            description="Deve ser igual ao Slug da jornada ou do microcertificado criado no SKYLAB."
          />

          <Input
            name="externalId"
            label="External ID"
            placeholder="External ID"
            description="Deve ser o igual ID da jornada ou do microcertificado criado no SKYLAB."
          />

          <Input
            name="durationInHours"
            label="Duração em horas"
            type="number"
            placeholder="Duração em horas"
          />
        </section>

        <section>
          <FileInput
            label="Logo"
            name="logo"
            placeholder="Logo do curso"
            path="courses/logos"
          />

          <FileInput
            label="Ícone"
            name="icon"
            placeholder="Ícone do curso"
            path="courses/icons"
          />

          <FileInput
            label="Badge"
            name="badge"
            placeholder="Badge do curso"
            path="courses/badges"
          />
        </section>

        <section>
          <Input
            name="description"
            label="Descrição"
            placeholder="Descrição do curso"
            multiline
          />
        </section>

        <section>
          <Dropdown
            name="type"
            label="Tipo"
            options={types.map((type) => ({
              title: type.label,
              id: type.value,
            }))}
            placeholder="Selecione o tipo"
          />

          <Dropdown
            name="product"
            label="Produto"
            options={products.map((product) => ({
              title: product.label,
              id: product.value,
            }))}
            placeholder="Selecione o produto"
          />
        </section>

        <Button type="submit" size="block" loading={submitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

CoursesForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default CoursesForm;
