import * as Yup from 'yup';

import { testimonialsSchema } from '../TestimonialForm';

import { tagFormsSchema } from '~/components/TagForms';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório.'),
  slug: Yup.string(),
  type: Yup.string(),
  forum_id: Yup.string(),
  description: Yup.string(),
  about: Yup.string(),
  csat_score: Yup.number()
    .transform((c, o) => (o === '' ? null : c))
    .min(0)
    .max(5)
    .nullable(),
  csat_reviews: Yup.number()
    .transform((c, o) => (o === '' ? null : c))
    .min(1)
    .nullable(),
  certificate_description: Yup.string(),
  is_recording: Yup.bool(),
  is_searchable: Yup.bool(),
  is_filterable: Yup.bool(),
  is_old: Yup.bool(),
  is_enabled_on_catalog: Yup.bool(),
  is_visible_for_users_with_access: Yup.bool(),
  is_certificable: Yup.bool(),
  is_free: Yup.bool(),
  free_until: Yup.string().nullable(),
  is_offer: Yup.bool(),
  is_entry_course: Yup.bool(),
  related_specialization_id: Yup.string().nullable(),
  pluto_offer_slug: Yup.string().nullable(),
  content_type: Yup.string(),
  thumbnail: Yup.string(),
  icon: Yup.string(),
  scope: Yup.string(),
  released_at: Yup.string(),
  workload: Yup.string(),
  has_after_access: Yup.bool(),
  has_trial_as_bonus: Yup.bool(),
  offer_url: Yup.string(),
  related_journey_id: Yup.string(),
  educators: Yup.array().of(Yup.string()),
  testimonials: testimonialsSchema,
  ...tagFormsSchema,
  landing_page_url: Yup.string(),
  intro_video_id: Yup.string(),
  teams: Yup.array().of(Yup.string()),
  has_cashback: Yup.bool(),
  should_redirect_to_internal_page: Yup.bool(),
  term_of_use_id: Yup.string(),
  circle_workspace_id: Yup.string(),
});

export default schema;
