/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { addHours } from 'date-fns';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { showError } from '~/utils';

function makeFormDefaultValues({ purchase, teams, plans }) {
  const team = teams?.find((t) => t.id === purchase.team_id);
  const plan = plans?.find((p) => p.id === purchase.plan_id);

  return {
    transaction: purchase.transaction,
    transaction_ext: purchase.transaction_ext,
    payment_engine: purchase.payment_engine,
    payment_type: purchase.payment_type,
    coupon: purchase.coupon,
    product: purchase.product,
    off: purchase.off,
    status: purchase.status,
    start_date: purchase.start_date && new Date(purchase.start_date),
    end_date: purchase.end_date && new Date(purchase.end_date),
    price: purchase.price,
    currency: purchase.currency,
    referral: purchase.referral,
    warranty_date: purchase.warranty_date && new Date(purchase.warranty_date),
    canceled_at: purchase.canceled_at && new Date(purchase.canceled_at),
    refunded_at: purchase.refunded_at && new Date(purchase.refunded_at),
    team: team && { value: team.id, label: team.title },
    plan: plan && { value: plan.id, label: plan.title },
    is_one_upgraded: purchase.is_one_upgraded,
    is_recurrence: purchase.is_recurrence,
    pluto_offer_id: purchase.pluto_offer_id,
    metadata: purchase.metadata,
  };
}

export function usePurchaseForm({ match, location }) {
  const [teams, setTeams] = useState([]);
  const [plans, setPlans] = useState([]);
  const [purchase, setPurchase] = useState({});

  const isUpdating = !!match.params.id && !location.pathname.includes('/new');

  const purchaseForm = useForm({
    values: makeFormDefaultValues({ purchase, teams, plans }),
  });

  useEffect(() => {
    async function loadTeams() {
      const { data } = await api.get('/teams');

      setTeams(data.data);
    }

    async function loadPlans() {
      const { data } = await api.get('/plans');

      setPlans(data.data);
    }

    async function loadPurchase(id) {
      try {
        const { data } = await api.get(`/purchases/${id}`);

        await Promise.all([loadTeams(), loadPlans()]);

        setPurchase(data);
      } catch (err) {
        showError(err);
      }
    }

    const { id } = match.params;

    if (id && isUpdating) {
      loadPurchase(id);
    } else {
      loadTeams();
      loadPlans();
    }
  }, []);

  async function handlePurchaseSubmit(data) {
    try {
      let { id } = match.params;
      let dataAsInput = data;

      dataAsInput.team_id = data?.team?.value || null;
      dataAsInput.plan_id = data?.plan?.value || null;

      if (!isUpdating) {
        dataAsInput = {
          ...data,
          user_id: id,
          start_date: addHours(data.start_date, 3),
          end_date: addHours(data.end_date, 3),
          canceled_at: addHours(data.canceled_at, 3),
          refunded_at: addHours(data.refunded_at, 3),
        };

        id = null;
      }

      await api.postOrPut('purchases', id, dataAsInput);

      toast.success(
        `Compra salva com sucesso!
          A página será atualizada automaticamente em alguns instantes!`,
        {
          autoClose: 3000,
          onClose: () => {
            window.location.reload();
          },
        },
      );
    } catch (err) {
      showError(err);
    }
  }

  return {
    teams,
    plans,
    purchase,
    isUpdating,
    purchaseForm,
    handlePurchaseSubmit,
  };
}
