/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';

import { DocumentsListHeader } from './Header';

import PagedList from '~/components/PagedList';
import { Actions } from '~/components/Table';
import ClipboardButton from '~/components/ClipboardButton';

const columns = [
  {
    label: 'Nome',
    value: 'name',
  },
  {
    label: 'Descrição',
    value: 'description',
  },
];

function DocumentsList({ history }) {
  return (
    <PagedList
      resource="documents"
      header={DocumentsListHeader}
      columns={columns}
      actions={(element) => {
        return (
          <td width={120}>
            <section style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
              <Actions
                mainAction={() => history.push(`/documents/edit/${element.id}`)}
              />
              <ClipboardButton title="Copiar ID" value={element.id} />
            </section>
          </td>
        );
      }}
    />
  );
}

export default DocumentsList;

DocumentsList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
