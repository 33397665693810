import React from 'react';
import PropTypes from 'prop-types';

import { differenceInDays, format, isAfter } from 'date-fns';
import { FaExternalLinkAlt } from 'react-icons/fa';

import { Container } from '../../Form/TeamUsers/styles';

import { Table } from '~/components/Table';
import Button from '~/components/Button';

export function PurchaseLicenses({ licenses }) {
  return (
    <Container>
      <h1>Licenças</h1>

      <Table>
        <thead>
          <tr>
            <th>Time</th>
            <th>Usuário</th>
            <th>Início do acesso</th>
            <th>Fim do Acesso</th>
            <th>Tempo de acesso</th>
          </tr>
        </thead>

        <tbody>
          {licenses?.map((license) => (
            <tr>
              <td>
                <section>
                  <strong>{license.contract.title}</strong>
                </section>
              </td>
              <td>
                <p>{license.user_id}</p>
              </td>
              <td>
                {format(new Date(license.contract.start_date), 'DD/MM/YYYY')}
              </td>
              <td>
                {format(new Date(license.contract.end_date), 'DD/MM/YYYY')}
              </td>
              <td>
                <section style={{ display: 'flex', flexDirection: 'column' }}>
                  {`${differenceInDays(
                    new Date(license.contract.end_date),
                    new Date(license.contract.start_date),
                  )} dias de acesso`}

                  <small>
                    {isAfter(license.contract.start_date, new Date())
                      ? `Começa em ${differenceInDays(
                          new Date(license.contract.start_date),
                          new Date(),
                        )} dias`
                      : `Restam ${differenceInDays(
                          new Date(license.contract.end_date),
                          new Date(),
                        )} dias`}
                  </small>
                </section>
              </td>
              <td>
                <a
                  href={`/b2b/contracts/edit/${license.contract.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    iconSize={12}
                    icon={FaExternalLinkAlt}
                    size="small"
                    alt="Ver contrato"
                  />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

PurchaseLicenses.propTypes = {
  licenses: PropTypes.arrayOf(
    PropTypes.shape({
      contract: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        start_date: PropTypes.string.isRequired,
        end_date: PropTypes.string.isRequired,
      }),
      user_id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
