import { createContext } from 'react';

import { get } from 'dot-prop-immutable';
import produce from 'immer';

export function move(setJourney, journey) {
  return (from, to) =>
    setJourney(
      produce(journey, (draft) => {
        const dragged = draft.nodes[from];
        draft.nodes.splice(from, 1);
        draft.nodes.splice(to, 0, dragged);
        draft.current = 'nodes';
        draft.currentIndex = to;
      }),
    );
}

export function addNode(setJourney, journey) {
  return () => {
    if (journey.currentIsNew) {
      return;
    }

    setJourney(
      produce(journey, (draft) => {
        draft.nodes.push({
          title: '',
          description: '',
          new: true,
          type: '',
          originalIndex: journey.nodes.length,
          layout: 'vertical-card',
          active: true,
          introductory: false,
          is_milestone: false,
          premiere: false,
          module: false,
          is_bonus: false,
          is_single_class_view: false,
        });

        draft.current = 'node';
        draft.currentIndex = journey.nodes.length;
        draft.currentIsNew = true;
      }),
    );
  };
}

export function toggleDeleteNode(setJourney, journey) {
  return (index) =>
    setJourney(
      produce(journey, (draft) => {
        if (!draft.nodes[index].deleted) {
          draft.currentIndex = -1;
        } else {
          draft.currentIndex = index;
          draft.current = 'nodes';
        }

        draft.nodes[index].deleted = !draft.nodes[index].deleted;
      }),
    );
}

export function selectCurrent(setJourney, journey) {
  return (index) =>
    setJourney(
      produce(journey, (draft) => {
        if (index === 'journey') {
          draft.current = 'journey';
          draft.currentIndex = -1;
        } else if (index !== draft.currentIndex) {
          draft.current = 'node';
          draft.currentIndex = index;
          draft.currentIsNew = journey.nodes[index].new;
        }
      }),
    );
}

export function updateJourney(setJourney, journey) {
  return (data) => {
    const formData = { ...journey, ...data };

    return setJourney(
      produce(journey, (draft) => {
        draft.title = formData.title;
        draft.slug = formData.slug;
        draft.about = formData.about;
        draft.csat_score = formData.csat_score;
        draft.csat_reviews = formData.csat_reviews;
        draft.subtitle = formData.subtitle;
        draft.description = formData.description;
        draft.thumbnail = formData.thumbnail;
        draft.icon = formData.icon;
        draft.type = formData.type;
        draft.forum_id = formData.forum_id;
        draft.scope = formData.scope;
        draft.certificate_description = formData.certificate_description;
        draft.is_recording = formData.is_recording;
        draft.is_cashback = formData.is_cashback;
        draft.is_searchable = formData.is_searchable;
        draft.is_filterable = formData.is_filterable;
        draft.content_type = formData.content_type;
        draft.tag_technologies = formData.tag_technologies;
        draft.tag_level = formData.tag_level;
        draft.tag_skills = formData.tag_skills;
        draft.tag_default = formData.tag_default;
        draft.released_at = formData.released_at;
        draft.is_old = formData.is_old;
        draft.is_enabled_on_catalog = formData.is_enabled_on_catalog;
        draft.is_certificable = formData.is_certificable;
        draft.is_free = formData.is_free;
        draft.free_until = formData.free_until;
        draft.is_offer = formData.is_offer;
        draft.is_entry_course = formData.is_entry_course;
        draft.related_specialization_id = formData.related_specialization_id;
        draft.workload = formData.workload;
        draft.has_after_access = formData.has_after_access;
        draft.has_trial_as_bonus = formData.has_trial_as_bonus;
        draft.offer_url = formData.offer_url;
        draft.related_journey_id = formData.related_journey_id;
        draft.is_visible_for_users_with_access =
          formData.is_visible_for_users_with_access;
        draft.testimonials = formData.testimonials;
        draft.educators = formData.educators;
        draft.landing_page_url = formData.landing_page_url;
        draft.intro_video_id = formData.intro_video_id;
        draft.teams = formData.teams;
        draft.has_cashback = formData.has_cashback;
        draft.pluto_offer_slug = formData.pluto_offer_slug;
        draft.should_redirect_to_internal_page =
          formData.should_redirect_to_internal_page;
        draft.term_of_use_id = formData.term_of_use_id;
        draft.circle_workspace_id = formData.circle_workspace_id;
      }),
    );
  };
}

export function updateNode(setJourney, journey) {
  return (data) =>
    setJourney(
      produce(journey, (draft) => {
        const node = get(draft, `nodes.${journey.currentIndex}`);

        draft.nodes[journey.currentIndex] = {
          ...node,
          ...data,
        };
        draft.currentIsNew = false;
      }),
    );
}

export default createContext({});
