import { HiDocumentSearch } from 'react-icons/hi'
import {
  IoHourglass,
  IoCloseCircle,
  IoCheckmarkCircle,
} from 'react-icons/io5'

export const DocumentStatusValues = {
  PENDING: 'PENDING',
  UNDER_REVIEW: 'UNDER_REVIEW',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  PENDING_SIGNATURE: 'PENDING_SIGNATURE'
};

export const DocumentStatusLabels = {
  PENDING: 'Pendente',
  UNDER_REVIEW: 'Em análise',
  REJECTED: 'Reprovado',
  APPROVED: 'Aprovado',
  PENDING_SIGNATURE: 'Assinatura pendente'
};

export const DocumentStatusIcons = {
  PENDING: IoHourglass,
  UNDER_REVIEW: HiDocumentSearch,
  REJECTED: IoCloseCircle,
  APPROVED: IoCheckmarkCircle,
  PENDING_SIGNATURE: IoHourglass
};

export const DocumentStatusDetails = {
  PENDING: {
    value: DocumentStatusValues.PENDING,
    label: DocumentStatusLabels.PENDING,
    icon: DocumentStatusIcons.PENDING,
    color: '#F48F56',
  },
  UNDER_REVIEW: {
    value: DocumentStatusValues.UNDER_REVIEW,
    label: DocumentStatusLabels.UNDER_REVIEW,
    icon: DocumentStatusIcons.UNDER_REVIEW,
    color: '#7159C1',
  },
  REJECTED: {
    value: DocumentStatusValues.REJECTED,
    label: DocumentStatusLabels.REJECTED,
    icon: DocumentStatusIcons.REJECTED,
    color: '#FF0000',
  },
  APPROVED: {
    value: DocumentStatusValues.APPROVED,
    label: DocumentStatusLabels.APPROVED,
    icon: DocumentStatusIcons.APPROVED,
    color: '#008000',
  },
  PENDING_SIGNATURE: {
    value: DocumentStatusValues.PENDING_SIGNATURE,
    label: DocumentStatusLabels.PENDING_SIGNATURE,
    icon: DocumentStatusIcons.PENDING_SIGNATURE,
    color: '#008000',
  }
};

export const DocumentStatusReviewed = [
  DocumentStatusValues.APPROVED,
  DocumentStatusValues.REJECTED,
  DocumentStatusValues.PENDING_SIGNATURE
];
