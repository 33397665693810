import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';

import { MdFileUpload } from 'react-icons/md';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import { Alert } from '~/components/ui/alert';
import api from '~/services/api';

export const ServiceContractSection = ({ contract }) => {
  const [isUploading, setIsUploading] = useState(false);

  const fileRef = useRef(null);

  const handleFile = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.value = null;
      fileRef.current.click();
    }
  }, []);

  const handleFileChange = useCallback(
    async (event) => {
      setIsUploading(true);

      try {
        const data = new FormData();

        data.append('file', event.target.files[0]);

        await api.post(
          `/users/documents/companies/${contract.company_id}`,
          data,
        );

        toast.success('Arquivo importado com sucesso!');
      } catch (error) {
        toast.error('Erro ao fazer upload do arquivo');
      } finally {
        setIsUploading(false);
      }
    },
    [contract.company_id],
  );

  return (
    <fieldset className="flex flex-col gap-4">
      <legend>Contrato de Prestação de Serviços</legend>

      <Alert
        title="Envio de contrato em massa"
        description="Faça o upload do arquivo com os dados dos alunos para enviar os contratos em massa para assinatura. Se precisar, baixe o [template de exemplo](https://docs.google.com/spreadsheets/d/1sMhTTvThrcm-DzyRfC7nt_S0sHgQQeNABVICnnIz7Vk)."
      />

      <div className="flex gap-3">
        <Button
          type="button"
          icon={MdFileUpload}
          onClick={handleFile}
          className="w-full justify-center"
          loading={isUploading}
        >
          {isUploading ? 'Fazendo Upload...' : 'Fazer Upload do Arquivo'}
        </Button>

        <input
          type="file"
          className="hidden"
          accept=".csv, text/csv"
          onChange={handleFileChange}
          ref={fileRef}
        />
      </div>
    </fieldset>
  );
};

ServiceContractSection.propTypes = {
  contract: PropTypes.shape({
    company_id: PropTypes.string,
  }).isRequired,
};
